import React, { Fragment } from "react"
import Title from "@webconnex/rainbow/typography/title"
import Select from "@webconnex/rainbow/forms/input-select"
import Button from "../button/button"
import Pane from "@webconnex/rainbow/layout/pane"
import List from "@webconnex/rainbow/components/list"
import SuggestedDonation from "../suggested-donation/suggested-donation"
import { FacebookShareButton, FacebookIcon } from "react-share"
import { ReactComponent as MC } from "./mastercard.svg"
import { ReactComponent as Visa } from "./visa.svg"
import { ReactComponent as AMEX } from "./amex.svg"
import { ReactComponent as Disc } from "./discover.svg"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"
import { StartDonation } from "../../types/donation"
import { SupporterType } from "../../types/supporter"
import useForm from "@webconnex/rainbow/hooks/use-form"
import { globalDefaultAccentColor } from "../../utils"
import { usePaginatedSupporters } from "../../hooks"
import { getFullName } from "../../utils/supporters"

interface Props {
	color?: string
	supporterId?: number
	biggestGift: number
	currentRevenue: number
	goalRevenue: number
	currency: SupportedCurrencyCodes
	hideSelection?: boolean
	generalFundId?: string
	startDonation: StartDonation
}

const StarterDonation: React.FC<Props> = ({
	color = globalDefaultAccentColor,
	supporterId = "",
	biggestGift,
	currentRevenue,
	goalRevenue,
	currency,
	hideSelection = false,
	generalFundId = "",
	startDonation,
}) => {
	const {
		result: { data },
	} = usePaginatedSupporters({ limit: 250 })
	const paginatedSupporters = data?.response?.supporters
	const supporters = paginatedSupporters?.records || []
	const supporterOptions = supporters
		? supporters.map((supporter: SupporterType, i: number) => {
				const {
					firstName: { value: first } = { value: "" },
					lastName: { value: last } = { value: "" },
				} = supporter.contact?.profile || {}
				return (
					<option key={`fund-select-${i}`} value={supporter.supporterId}>
						{first && first + " "}
						{last}
					</option>
				)
		  })
		: []

	// Determining whether or not to show the select drop down
	const hideSelect =
		(hideSelection && supporterId) ||
		(supporterOptions.length === 0 && generalFundId)

	const prePopulatedId = supporterId || generalFundId

	const { values, inputProps, setValue } = useForm({
		initialValues: {
			amount: "",
			supporter: supporterId.toString(),
		},
	})

	const handleSuggestedChange = (amount: string) => {
		setValue("amount", amount)
	}

	const setDonation = () => {
		let startAmount = ""
		if (!isNaN(parseFloat(values.amount))) {
			startAmount = values.amount
		}
		const selectedSupporter = supporters
			? supporters.find(
					(supporter: SupporterType) =>
						supporter.supporterId === Number(values.supporter)
			  )
			: ({} as any as SupporterType)

		const event = new CustomEvent("wbcxCheckpointBilling")
		document.body.dispatchEvent(event)
		const fullName = getFullName(selectedSupporter)
		startDonation(fullName || "", Number(values.supporter), startAmount)
	}

	return (
		<Pane spacing={5}>
			<Title size={3} className="fwb">
				Make a Donation
			</Title>
			<SuggestedDonation
				biggestGift={biggestGift}
				currentRevenue={currentRevenue}
				goalRevenue={goalRevenue}
				currency={currency}
				inputProps={inputProps.amount}
				handleSuggestedChange={handleSuggestedChange}
			/>
			{!hideSelect && (
				<Select
					aria-label="Optionally select an individual to support"
					{...inputProps.supporter}
					defaultValue={values.supporter}
				>
					<option value={generalFundId}>
						Select an individual to support (optional)
					</option>
					{supporterOptions}
				</Select>
			)}
			{hideSelect && (
				<input name="selectedFund" value={prePopulatedId} type="hidden" />
			)}
			<Button
				style={{
					background: color,
					boxShadow: "none",
				}}
				size="default"
				emphasis="high"
				className="flex-grow-1 fwb md_dn"
				onClick={setDonation}
			>
				Give Now
			</Button>
			<Pane
				xs="row"
				spacing={3}
				className="fixed bottom-0 left-0 w-100 z-999 bg-gray-10 pv-3 ph-4 mb-0 md_mb-6 md_pa-0 md_relative md_bg-white"
			>
				<Button
					style={{
						background: color,
						boxShadow: "none",
					}}
					size="default"
					emphasis="high"
					className="flex-grow-1 fwb"
					onClick={setDonation}
				>
					Give Now
				</Button>
				<FacebookShareButton url={window.location.href} className="flex-grow-1">
					<Button
						style={{
							color,
							background: "#eee",
							boxShadow: "none",
						}}
						size="default"
						emphasis="high"
						className="w-100 fwb"
					>
						<FacebookIcon
							size={28}
							iconBgStyle={{ fill: "#eee" }}
							logoFillColor={color}
						/>
						<span className="pr-2">Share</span>
					</Button>
				</FacebookShareButton>
			</Pane>
			<List xs="row" spacing={3}>
				<MC width="30" />
				<Visa width="30" />
				<AMEX width="30" />
				<Disc width="70" />
			</List>
		</Pane>
	)
}

export default StarterDonation
