import React from "react"
import cx from "classnames"
import ImageGallery from "react-image-gallery"
import Button from "../button/button"
import Icon from "@webconnex/rainbow/components/icon"
import { S3ImageType } from "@webconnex/rainbow/types/image"
import {
	buildConfig,
	generateURL,
} from "@webconnex/rainbow/components/s3-image/s3-util"

const s3ImageServiceUrl =
	process.env.S3_IMAGE_SERVICE_URL ||
	process.env.REACT_APP_S3_IMAGE_SERVICE_URL ||
	"localhost"
interface Props {
	images: S3ImageType[]
}

const navButtons = (direction: string, onClick: any, disabled: boolean) => {
	return (
		<Button
			className={cx(`image-gallery-custom-${direction}-nav`, "z-1")}
			disabled={disabled}
			onClick={onClick}
			iconOnly
			style={{
				background: "rgba(255,255,255,0.8)",
				border: "none",
			}}
		>
			<Icon iconName={`chevron-${direction}`} />
		</Button>
	)
}

const renderLeftNav = (onClick: any, disabled: boolean) =>
	navButtons("left", onClick, disabled)

const renderRightNav = (onClick: any, disabled: boolean) =>
	navButtons("right", onClick, disabled)

const Gallery: React.FC<Props> = ({ images }) => {
	if (!images || images.length === 0) {
		return null
	}

	// We don't want super wide thumbnails, so if there are less than three,
	// we are setting widths as if there are three.
	const gallerySize = images.length < 3 ? 3 : images.length

	const width = 700
	const height = 415
	const galleryArray = images
		// remove empty images
		.filter((image: S3ImageType) => image.originalFilename)
		.map((image: S3ImageType) => {
			if (image.type === "raw") {
				// If you look in the utils.ts file at the project root you can see
				// this will happen when we can't parse a CP image into a proper
				// S3 image object.
				return {
					original: image.originalFilename,
					thumbnail: image.originalFilename,
				}
			}

			const thumbnailConfig = buildConfig(
				s3ImageServiceUrl,
				image,
				Math.round(width / gallerySize),
				Math.round(height / gallerySize),
				"cover"
			)
			const originalConfig = buildConfig(
				s3ImageServiceUrl,
				image,
				width,
				height,
				"cover"
			)
			return {
				original: generateURL(originalConfig),
				thumbnail: generateURL(thumbnailConfig),
			}
		})
	return (
		<ImageGallery
			items={galleryArray}
			showFullscreenButton={false}
			showPlayButton={false}
			renderLeftNav={renderLeftNav}
			renderRightNav={renderRightNav}
		/>
	)
}

export default Gallery
