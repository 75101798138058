import { parseImageObject } from "../../utils"
import { toPage } from "../../utils/navigation"
import moment from "moment-timezone"
import { UseS3Upload } from "@webconnex/rainbow/hooks/use-s3-upload"

export const defaultIntro = `Hi Friends and Family, I am raising money for this campaign. I sincerely appreciate your support for this fundraiser and my goal. You can donate now by hitting “Give Now”

You can give with any major credit card and it only takes 30 seconds. I appreciate it so very much. Thank you for joining me!`

export const submitSignupForm = async (
	values: Record<string, any>,
	profileImage: UseS3Upload,
	galleryImages: UseS3Upload[],
	domain: string = "",
	subdomain: string,
	pageSlug: string = "",
	goalCurrency: string,
	createSupporter: any,
	setError: any,
	refetch: () => void
) => {
	const submitValues = { ...values }

	delete submitValues.confirmEmail

	submitValues.fundraisingDeadline = values.fundraisingDeadline + "T00:00:00Z"

	if (!moment(submitValues.fundraisingDeadline).isValid()) {
		setError("Please select a fundraising deadline")
		return
	}

	submitValues.goalAmount = parseInt(values.goalAmount, 10)
	submitValues.includeMasterPhotos = values.includeMasterPhotos === "include"

	const variables = {
		...submitValues,
		goalCurrency,
		domain,
		subdomain,
		pageSlug,
		profileImage: null,
		galleryImages: parseImageObject(galleryImages),
	}

	if (profileImage.file.success) {
		variables.profileImage = parseImageObject(profileImage)
	}

	const { data: supporterData } = await createSupporter({
		variables,
	})

	if (!supporterData || !supporterData.response) {
		setError("We were unable to create your supporter page, please try again.")
	}

	if (
		!supporterData.response.success ||
		!supporterData.response.supporter.supporterId
	) {
		const submissionErrors = supporterData.response.errors

		let errMsg = "Unable to create your supporter page."
		if (submissionErrors.length > 0) {
			errMsg = submissionErrors[0].messages
		}

		setError(errMsg)
		return
	}

	setTimeout(() => {
		toPage(
			`/${pageSlug}/fundraiser/${supporterData.response.supporter.supporterId}`
		)
		refetch()
	})

	return
}
