import { useEffect } from "react"
import { v5 as uuidv5 } from "uuid"

interface Metric {
	fr: string // formhash
	tk: string // user token
	sw: number // window inner width
	sh: number // window inner height
	rf: string // referer
	r: number // repeat visitor
	ptk: string // previous user token
	sp?: number // supporter Id
}
enum Checkpoint {
	// PreVisit is a pre-visit (form not open).
	PreVisit = 10,
	// PageVisit is a simple page visit.
	PageVisit = 100,
	// EngagedVisit is an "engaged" visit.
	EngagedVisit = 200,
	// BillingVisit is a visit to Billing.
	BillingVisit = 300,
	// PageOrder is a completed registration.
	PageOrder = 400,
}

const setCookie = (name: string, value: string, days: number): void => {
	let expires = ""
	if (days) {
		const date = new Date()
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
		expires = "; expires=" + date.toUTCString()
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/"
}
const getCookie = (name: string): string => {
	const nameEQ = name + "="
	const ca = document.cookie.split(";")
	ca.forEach(c => {
		while (c.charAt(0) == " ") {
			c = c.substring(1, c.length)
			if (c.indexOf(nameEQ) === 0) {
				return c.substring(nameEQ.length, c.length)
			}
		}
	})
	return ""
}

const setupMetric = (formHash: string, supporterId?: number): Metric => {
	const userToken = uuidv5(window.location.href, uuidv5.URL)

	// Check for a referral cookie, if found set it to the cookie value.
	const storedReferrer = getCookie(formHash + "_referrer")

	// Set whether it's a repeat visit and set repeat flag accordingly.
	let r = 0
	if (getCookie(formHash)) {
		r = 1 // Repeat Visitor
	} else {
		setCookie(formHash, Date.now().toString(), 180)
	}

	// If it's a repeat visit then use the old token.
	let ptk = ""
	if (getCookie("token")) {
		ptk = getCookie("token")
	} else {
		setCookie("token", userToken, 180)
	}

	const metric: Metric = {
		fr: formHash,
		tk: userToken,
		sw: window.innerWidth,
		sh: window.innerHeight,
		rf: storedReferrer ? atob(storedReferrer) : document.referrer,
		r,
		ptk,
	}

	if (supporterId) {
		metric.sp = supporterId
	}

	return metric
}

export function useCheckpoints(formHash: string, supporterId?: number) {
	const metric = setupMetric(formHash, supporterId)

	const fetchMetricUrl = (checkpoint: number) => {
		const searchString = {
			...metric,
			chk: checkpoint,
			dt: Date.now(),
		}

		// Logstash needs the checkpoint data to be in a specific order.
		const metricOrder = [
			"dt",
			"fr",
			"tk",
			"chk",
			"sw",
			"sh",
			"rf",
			"r",
			"ptk",
			"sp",
		]
		fetch(
			process.env.PUBLIC_URL +
				"/images/metrics.gif?" +
				JSON.stringify(searchString, metricOrder)
		)
	}

	const bodyOnFirstInput = (event: Event) => {
		document.body.removeEventListener(event.type, bodyOnFirstInput, true)
		fetchMetricUrl(Checkpoint.EngagedVisit)
	}

	const bodyOnBillingClick = (event: Event) => {
		document.body.removeEventListener(event.type, bodyOnBillingClick, true)
		fetchMetricUrl(Checkpoint.BillingVisit)
	}

	useEffect(() => {
		document.body.addEventListener("input", bodyOnFirstInput, true)
		document.body.addEventListener(
			"wbcxCheckpointBilling",
			bodyOnBillingClick,
			false
		)
		document.body.addEventListener(
			"wbcxCheckpointOrder",
			() => {
				fetchMetricUrl(Checkpoint.PageOrder)
			},
			false
		)
		fetchMetricUrl(Checkpoint.PageVisit)
	}, [])
}
