import React from "react"
import { default as RainbowButton } from "@webconnex/rainbow/components/button"

interface Props {
	children?: React.ReactNode
	style?: any
	[prop: string]: any
}

const Button: React.FC<Props> = ({ style, children, as, ...rest }) => {
	return (
		<RainbowButton
			variant="default"
			emphasis="default"
			size="small"
			as={as}
			style={style}
			{...rest}
		>
			{children}
		</RainbowButton>
	)
}

Button.defaultProps = {
	style: {},
	as: "button",
}

export default Button
