import React from "react"
import moment from "moment-timezone"
import Title from "@webconnex/rainbow/typography/title"
import Text from "@webconnex/rainbow/typography/text"
import { InputTextField } from "@webconnex/rainbow/forms/input-text"
import { InputCurrencyField } from "@webconnex/rainbow/forms/input-currency"
import { InputTextareaField } from "@webconnex/rainbow/forms/input-textarea"
import InputCheckbox from "@webconnex/rainbow/forms/input-checkbox"
import Grid, { GridColumn } from "@webconnex/rainbow/layout/grid"
import Pane from "@webconnex/rainbow/layout/pane"
import oddCouple from "./the-odd-couple.png"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"
import DayPickerInput from "react-day-picker/DayPickerInput"
import "react-day-picker/lib/style.css"
import TimeZoneSelect from "./timezone-options"
import { UseS3Upload } from "@webconnex/rainbow/hooks/use-s3-upload"
import { InputImageUpload } from "@webconnex/rainbow/forms/input-image-upload"
import { S3ImageType } from "@webconnex/rainbow/types/image"

const s3ImageServiceURL =
	process.env.S3_IMAGE_SERVICE_URL ||
	process.env.REACT_APP_S3_IMAGE_SERVICE_URL ||
	"localhost"
interface Props {
	inputProps: any
	allowGoalChange?: boolean
	allowDeadlineChange?: boolean
	currency: SupportedCurrencyCodes
	profileImage: UseS3Upload
	galleryImages: UseS3Upload[]
	changeFundraisingDeadline: (date: string) => void
}

const CreateEditPage: React.FC<Props> = ({
	inputProps,
	allowGoalChange,
	allowDeadlineChange,
	currency,
	changeFundraisingDeadline,
	profileImage,
	galleryImages,
}) => {
	const handleDayChange = (selectedDay: Date): void => {
		changeFundraisingDeadline(moment(selectedDay).format("YYYY-MM-DD"))
	}

	const galleryUploads = galleryImages.map((galleryImage, index) => (
		<InputImageUpload
			s3ImageServiceURL={s3ImageServiceURL}
			key={`gallery-${index}`}
			image={galleryImage.file as S3ImageType}
			renderedImageWidth={140}
			renderedImageHeight={83}
			setImageHandler={galleryImage.selectFile}
			removeImageHandler={galleryImage.removeFile}
		/>
	))

	return (
		<Grid className="mt-4" gutter={8}>
			<GridColumn md={6}>
				<InputCurrencyField
					required
					label="Goal Amount"
					convertToInteger
					fixedDecimalScale
					{...inputProps.goalAmount}
					currencyCode={currency}
					disabled={!allowGoalChange}
				/>
			</GridColumn>
			<GridColumn>
				<Title size={6} className="fwb mb-1">
					Tell Your Story
				</Title>
				<Text variant="light" size={3}>
					Tell people about your story and about what you are doing and why
					people should contribute to your project and how you will use the
					money raised.
				</Text>
				<InputTextareaField
					className="mt-2"
					required
					placeholder={inputProps.introText.value}
					{...inputProps.introText}
				/>
			</GridColumn>
			<GridColumn>
				<Title size={6} className="fwb mb-1">
					Thank You Message
				</Title>
				<Text variant="light" size={3}>
					We will include this thank you message in the email receipt donors to
					your page receive.
				</Text>
				<InputTextareaField
					className="mt-2"
					required
					placeholder={inputProps.customThankYou.value}
					{...inputProps.customThankYou}
				/>
			</GridColumn>
			<GridColumn md={6}>
				<DayPickerInput
					component={(props: any) => (
						<InputTextField
							label="Fundraising Deadline"
							disabled={!allowDeadlineChange}
							{...props}
						/>
					)}
					value={inputProps.fundraisingDeadline.value}
					onDayChange={handleDayChange}
					dayPickerProps={{
						fromMonth: new Date(),
						disabledDays: {
							before: new Date(),
						},
					}}
				/>
			</GridColumn>
			<GridColumn md={6}>
				<TimeZoneSelect
					inputProps={inputProps.timeZone}
					disabled={!allowDeadlineChange}
				/>
			</GridColumn>
			<GridColumn>
				<Title size={6} className="fwb mb-1">
					Profile Photo
				</Title>
				<Text variant="light" size={3}>
					Upload a photo that shows your face in the middle of the image. It
					will be cropped to both a circle and a square. Ideal size is 200px
					&times; 200px.
				</Text>
				<img
					src={oddCouple}
					alt="Example cropping of profile photos"
					className="db mb-4"
					width="175"
				/>
				<InputImageUpload
					s3ImageServiceURL={s3ImageServiceURL}
					className="md_max-w-20"
					image={profileImage.file as S3ImageType}
					renderedImageWidth={200}
					renderedImageHeight={200}
					setImageHandler={profileImage.selectFile}
					removeImageHandler={profileImage.removeFile}
				/>
			</GridColumn>
			<GridColumn>
				<Title size={6} className="fwb mb-1">
					Additional Photos
				</Title>
				<Text variant="light" size={3}>
					Upload more photos that will help people connect with the purpose of
					your fundraiser. Ideal size is 700px &times; 415px
				</Text>
				<Pane xs="row-wrap" className="mt-1" spacing={3}>
					{galleryUploads}
				</Pane>
			</GridColumn>
			<GridColumn>
				<InputCheckbox
					label="Include the photos from the main fundraiser page on my personal page"
					{...inputProps.includeMasterPhotos}
					value="include"
					name="includeMasterPhotos"
					checked={inputProps.includeMasterPhotos.value === "include"}
				/>
			</GridColumn>
			<GridColumn>
				<Title size={6} className="fwb mb-1">
					Video
				</Title>
				<Text variant="light" size={3}>
					Add a URL from YouTube, Vimeo, or Wistia (optional)
				</Text>
				<InputTextField {...inputProps.videoURL} />
			</GridColumn>
		</Grid>
	)
}
export default CreateEditPage
