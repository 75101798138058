import React, { Fragment } from "react"
import { loader } from "graphql.macro"
import { useMutation } from "@apollo/react-hooks"
import Text from "@webconnex/rainbow/typography/text"
import Title from "@webconnex/rainbow/typography/title"
import Link from "@webconnex/rainbow/typography/link"
import Page, { PageBody } from "@webconnex/rainbow/layout/page"
import { InputTextField } from "@webconnex/rainbow/forms/input-text"
import { SuperNav, Masthead, Button, CustomHead } from "../../components/"
import { ErrorMessages } from "../../types/error"
import useForm from "@webconnex/rainbow/hooks/use-form"
import { email, required } from "@webconnex/rainbow/validators"
import { parsePublishedPageResponse } from "../../utils"
import { toPage } from "../../utils/navigation"
import { usePage } from "../../contexts"
const generateLogin = loader("../../graphql/mutations/generate-login.graphql")

const Login: React.FC = () => {
	const { pageSlug, result: pageResult } = usePage()
	const [
		generateLoginMutation,
		{ data: linkData, loading: linkLoading, called },
	] = useMutation(generateLogin, {
		fetchPolicy: "no-cache",
	})
	const submit = async (submitValues: Record<string, any>) => {
		generateLoginMutation({
			variables: {
				accountId,
				email: submitValues.email,
				redirectUrl: window.location.href,
			},
		})
	}

	const { inputProps, isSubmitting, handleSubmit } = useForm({
		initialValues: { email: "" },
		validations: {
			email: {
				...email({
					invalid_email_format: ErrorMessages.email,
					too_short: ErrorMessages.email,
					too_long: ErrorMessages.email,
				}),
				...required({ required: "Please enter your email address." }),
			},
		},
		onSubmit: submit,
	})

	if (!pageResult?.data?.response?.publishedPage?.pageSnapshot) {
		return null
	}

	const { accountId } = pageResult?.data?.response?.publishedPage

	const { name, masthead, accentColor, fonts, settings, p2pField } =
		parsePublishedPageResponse(
			pageResult?.data?.response?.publishedPage?.pageSnapshot
		)

	return (
		<Fragment>
			<CustomHead fonts={fonts} settings={settings} title={"Login | " + name} />
			<SuperNav
				canCreatePage={true}
				contactEmail={p2pField.contactEmail}
				pageSlug={pageSlug}
			/>
			<Masthead
				src={masthead.path}
				align={masthead.align}
				height={masthead.height}
			/>
			<Page maxWidth="small">
				<PageBody className="w-100">
					<Title className="mb-8 fwb" style={{ color: accentColor }}>
						Get login link to your personal fundraising page for {name}
					</Title>
					<Title size={3} className="fwb">
						Enter Your Email
					</Title>
					<Text size={3} variant="medium">
						Or{" "}
						<Link
							variant="primary"
							style={{ color: accentColor }}
							size={3}
							onClick={() => {
								toPage(`/${pageSlug}/sign-up`)
							}}
						>
							Sign Up
						</Link>{" "}
						instead.
					</Text>
					<div className="mt-4">
						{!linkLoading && linkData?.response?.success && (
							<Text variant="success" as="p">
								A login link has been sent to your email. You can use it for the
								next 24 hours.
							</Text>
						)}
						{!linkLoading && called && !linkData?.response?.success && (
							<Text variant="danger" as="p">
								We weren't able to send you a login link. If you think this is
								in error, you can{" "}
								<Link
									style={{ color: "inherit" }}
									href={`mailto:${p2pField.contactEmail}`}
								>
									contact us
								</Link>
							</Text>
						)}
						{window.location.hash === "#error" && !linkLoading && !linkData && (
							<Text variant="danger" as="p">
								Your log in didn't work. Login links only work for 24 hours, so
								you may need to fill out this form again. If you think
								something's up, you can{" "}
								<Link
									style={{ color: "inherit" }}
									href={`mailto:${p2pField.contactEmail}`}
								>
									contact us
								</Link>
							</Text>
						)}
					</div>
					<form className="mt-6" noValidate onSubmit={handleSubmit}>
						<InputTextField required label="Email" {...inputProps.email} />
						<Button
							style={{
								background: accentColor,
								border: "none",
							}}
							className="mt-8 fwb white w-100 md_w-auto"
							size="default"
							type="submit"
							disabled={isSubmitting}
							loading={isSubmitting}
						>
							Send Login Link
						</Button>
					</form>
				</PageBody>
			</Page>
		</Fragment>
	)
}

export default Login
