import { MaintenanceData, MaintenanceError } from "./maintenance-error"
import { RegisterError, RegisterData } from "./register-error"

export type RegisterPayload = {
	code: number
	data: RegisterData
}

export type RegisterResponse = {
	hash: string
	data: RegisterData
}

export async function register(
	form: any,
	publishedPagePath: string
): Promise<RegisterResponse> {
	const url = process.env.REACT_APP_REG_API_URL + "/peer-to-peer"
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"X-WBX-PAGE-URL": publishedPagePath,
		},
		body: JSON.stringify(form),
	})

	const payload: RegisterPayload = await response.json()
	let registerResponse = {
		hash: payload.data.hash,
		data: payload.data.data,
	}

	if (!response.ok) {
		if (response.status === 503) {
			const errorData: MaintenanceData = await response.json()
			throw new MaintenanceError(errorData)
		} else if (response.status === 400 && payload.data.scaSecret) {

			// Handle SCA authentication.
			await scaHandler(payload.data.scaSecret, async (captureReference: string) => {

				// Set capture reference and registration hash to complete the original transaction.
				form.captureReference = captureReference
				form.hash = payload.data.hash

				// Resubmit the transaction and return the response from this submission.
				registerResponse = await register(form, publishedPagePath)
			}, (error: string) => {
				throw new RegisterError(response.status, payload.data)
			})
		} else {
			throw new RegisterError(response.status, payload.data)
		}
	}

	return registerResponse
}

// Default handler for SCA that can be overridden by gateway specific init function.
let scaHandler = async (scaSecret: string, success: any, error: any) => {
	error("Cannot authenticate this card payment.")
}

// Declare stripe SDK variables.
declare var Stripe: any
let stripe = {} as any

// Handler for Stripe SCA.
const stripeSCAHandler = async (scaSecret: string, success: any, error: any) => {
	switch (true) {
		case scaSecret.startsWith("pi_"):
			await stripe.handleCardAction(scaSecret).then(async (result: any) => {
				if (!result.paymentIntent || result.error) {
					error(result.error && result.error.message || "Unknown Error")
					return
				}
				await success(result.paymentIntent.id)
			})
			break
		case scaSecret.startsWith("seti_"):
			await stripe.confirmCardSetup(scaSecret).then(async (result: any) => {
				if (!result.setupIntent || result.error) {
					error(result.error && result.error.message || "Unknown Error")
					return
				}
				await success(result.setupIntent.id)
			})
			break
		default:
			error("Cannot authenticate this card payment.")
	}
}

// Initialize gateway specific SCA handler.
export const getCreditCardGateway = (pageResult: any): any => {
	const gateways = pageResult?.data?.response?.publishedPage?.gateways
	|| pageResult?.data?.response?.supporter?.masterPage?.gateways

	gateways?.forEach((gateway: any) => {
		if (gateway.paymentMethodProvider.paymentMethod.identifier == "CC") {
			return gateway
		}
	});
}

// Initialize gateway specific SCA handler.
export const initializeSCA = (pageResult: any) => {
	const creditCardGateway = getCreditCardGateway(pageResult)

	if (creditCardGateway && creditCardGateway.auth) {
		switch (creditCardGateway.type) {
			case "stripe":
				initializeStripe(creditCardGateway.auth)
		}
	}
}

// Initialize the Stripe SDK.
const initializeStripe = (auth: any) => {

	// Check if Stripe has already been initialized.
	if(Object.keys(stripe).length > 0) {
		return
	}

	const script = document.createElement('script')
	script.src = "https://js.stripe.com/v3/"
	script.onload = function () {
		if (auth.stripeAccountID) {
			stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
				stripeAccount: auth.stripeAccountID,
			})
		} else {
			stripe = Stripe(auth.publicKey)
		}
		scaHandler = stripeSCAHandler
	}

	document.head.appendChild(script)
}
