const fallbackCopyTextToClipboard = (text: string): void => {
	var textArea = document.createElement("textarea")
	textArea.value = text
	textArea.style.position = "fixed" //avoid scrolling to bottom
	document.body.appendChild(textArea)
	textArea.focus()
	textArea.select()

	try {
		var successful = document.execCommand("copy")
		var msg = successful ? "successful" : "unsuccessful"
		console.log("Fallback: Copying text command was " + msg)
	} catch (err) {
		console.error("Fallback: Oops, unable to copy", err)
	}

	document.body.removeChild(textArea)
}

export const copyTextToClipboard = (text: string): void => {
	try {
		navigator.clipboard.writeText(text)
	} catch {
		fallbackCopyTextToClipboard(text)
	}
}

interface NetworkColors {
	fb: string
	tw: string
}

export const networkColors: NetworkColors = {
	fb: "#3B5998",
	tw: "#55ACEE",
}

export const generateStyles = (network?: "fb" | "tw"): object => {
	let bg: string = "#999"
	if (network && networkColors.hasOwnProperty(network)) {
		bg = networkColors[network]
	}
	return {
		color: "#fff",
		background: bg,
		border: "none",
		fontSize: "12px",
		fontWeight: "700",
		borderRadius: "8px",
	}
}
