import { UseS3Upload } from "@webconnex/rainbow/hooks/use-s3-upload"
import { S3ImageType } from "@webconnex/rainbow/types/image"
import { email, required } from "@webconnex/rainbow/validators"
import { ErrorMessages } from "../../types/error"
import { parseImageObject } from "../../utils"

const emailValid = {
	...email({
		invalid_email_format: ErrorMessages.email,
		too_short: ErrorMessages.email,
		too_long: ErrorMessages.email,
	}),
	...required({ required: "Please enter your email address." }),
}

export const editValidations = {
	goalAmount: () =>
		required({
			required: "Please enter a goal amount.",
		}),
	introText: () =>
		required({
			required: "Please enter a your story.",
		}),
	timeZone: () =>
		required({
			required: "Please choose a timezone for your fundraising deadline.",
		}),
}

export const createValidations = {
	firstName: () =>
		required({
			required: "Please enter your first name.",
		}),
	lastName: () =>
		required({
			required: "Please enter your last name.",
		}),
	email: () => emailValid,
	confirmEmail: () => ({
		...emailValid,
		equality: {
			attribute: "email",
			message: "Please make sure your email addresses match.",
			comparator: (v1: any, v2: any) => {
				// This is a bit of a hack for now.
				// The way useForm handles validation onBlur and onChange, each
				// validation is independently, so the function has no
				// context for "email" When it's submitted the validations are
				// run together so this will kick an error on submit.
				if (v1 && v2 === undefined) {
					return true
				}
				return v1 === v2
			},
		},
	}),
	...editValidations,
}

export const submitEditPageForm = async (
	supporterId: number,
	values: Record<string, any>,
	profileImage: UseS3Upload,
	galleryImages: UseS3Upload[],
	originalGalleryImages: S3ImageType[],
	goalCurrency: string,
	editSupporter: any,
	setError: any,
	closeCallBack: () => void
) => {
	const submitValues = { ...values }

	submitValues.fundraisingDeadline = values.fundraisingDeadline + "T00:00:00Z"
	submitValues.goalAmount = parseInt(values.goalAmount, 10)
	submitValues.includeMasterPhotos = values.includeMasterPhotos === "include"

	const newGalleryImages: S3ImageType[] = parseImageObject(galleryImages)
	const newGalleryIds = newGalleryImages.map(image => image.id)

	// if there are any original images that aren't part
	// of the new images array, that means they have been
	// deleted so we need to add them back for deletion
	const galleryImagesToDelete: S3ImageType[] = !originalGalleryImages?.length
		? []
		: originalGalleryImages
				.map(image => ({
					id: image.id,
					delete: true,
					s3Key: image.s3Key,
					s3Bucket: image.s3Bucket,
					originalFilename: image.originalFilename,
				}))
				.filter(image => !newGalleryIds.includes(image.id))

	const variables = {
		...submitValues,
		profileImage: null,
		galleryImages: newGalleryImages.concat(galleryImagesToDelete),
		goalCurrency,
		supporterId,
	}

	if (
		(profileImage.file.s3Key && profileImage.file.originalFilename) ||
		profileImage.file.deleted
	) {
		variables.profileImage = parseImageObject(profileImage)
	}

	const { data: supporterData } = await editSupporter({
		variables,
	})

	if (!supporterData || !supporterData.response) {
		setError("We were unable to edit your supporter page, please try again.")
	}

	if (
		!supporterData.response.success ||
		!supporterData.response.supporter.supporterId
	) {
		const submissionErrors = supporterData.response.errors

		let errMsg = "Unable to edit your supporter page."
		if (submissionErrors.length > 0) {
			errMsg = submissionErrors[0].messages
		}

		setError(errMsg)
		return
	}
	closeCallBack()
	return
}
