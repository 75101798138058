import React from "react"
import cx from "classnames"
import Text from "@webconnex/rainbow/typography/text"
import Link from "@webconnex/rainbow/typography/link"
import Pane from "@webconnex/rainbow/layout/pane"
import FormattedCurrency from "@webconnex/rainbow/components/formatted-currency"
import Icon from "@webconnex/rainbow/components/icon"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"
import { globalDefaultAccentColor } from "../../utils"
import { RegistrationPublic } from "../../types/registration"
import { S3Image } from "@webconnex/rainbow/components/s3-image"
import { toPage } from "../../utils/navigation"
import { getFullName } from "../../utils/supporters"

interface Props {
	pageSlug: string
	donor: any
	index: number
	currency: SupportedCurrencyCodes
	color?: string
	supporterView?: boolean
}

const Donor: React.FC<Props> = ({
	pageSlug,
	donor,
	index,
	color = globalDefaultAccentColor,
	currency,
	supporterView = false,
}) => {
	const {
		contact: {
			profile: {
				firstName: { value: donorFirst },
				lastName: { value: donorLast },
			},
		},
		total: { amount = 0 },
		fundraiser: { firstName = "", lastName = "", image = "", url = "" } = {},
		biggestDonor = false,
		goalReached = false,
	} = donor
	const supporter = donor.supporter
	const supporterName = getFullName(supporter)
	const profileImage = supporter?.profileImage

	// P2P registrations are guaranteed to only have 1 registration.
	const donorOrder: RegistrationPublic = donor
	const {
		registrants: [{ $fields: regFields }],
	} = donorOrder
	const { $value: displayName = "" } = regFields.find(
		field => field.$key === "displayName"
	)!

	const commentField = regFields.find(field => field.$key === "donationComment")
	const comment = commentField ? commentField.$value : ""

	const donorDisplayName = displayName || donorFirst + " " + donorLast
	const hasBadge = biggestDonor || goalReached
	const hasBothBadges = biggestDonor && goalReached

	return (
		<Pane key={`donor-${index}`} spacing={4}>
			<Pane xs="row" className="items-center" spacing={3}>
				{!supporterView && (
					<div className="w-7 h-7">
						{profileImage && (
							<S3Image
								s3ImageServiceURL={
									process.env.S3_IMAGE_SERVICE_URL ||
									process.env.REACT_APP_S3_IMAGE_SERVICE_URL ||
									"localhost"
								}
								image={profileImage}
								className="b-r-50 w-100"
								width={200}
								height={200}
							/>
						)}
					</div>
				)}
				<Pane
					lg="row"
					spacing={2}
					className="min-h-7 justify-center md_justify-between md_items-center flex-grow-1"
				>
					{supporterView && (
						<Text size={3}>
							{donorDisplayName} gave{" "}
							<FormattedCurrency
								convertFromInteger
								value={amount}
								precision={0}
								currencyCode={currency}
							/>
						</Text>
					)}
					{!supporterView && (
						<Text size={3}>
							{supporterName ? (
								<Link
									variant="primary"
									size={3}
									color={{ color }}
									onClick={() => {
										toPage(`/${pageSlug}/fundraiser/${supporter.supporterId}`)
									}}
								>
									{supporterName}
								</Link>
							) : (
								"Campaign"
							)}{" "}
							received{" "}
							<FormattedCurrency
								convertFromInteger
								value={amount}
								precision={0}
								currencyCode={currency}
							/>{" "}
							from {donorDisplayName}
						</Text>
					)}

					{hasBadge && (
						<Pane xs="row" spacing={5} className="lg_mh-2">
							{biggestDonor && (
								<Text
									className="ttu fwb"
									style={{ fontSize: "10px", letterSpacing: ".5px", color }}
								>
									<Icon placement="left" iconName="solid-cockade" />
									Biggest Donor
								</Text>
							)}
							{goalReached && (
								<Text
									className="ttu fwb"
									style={{ fontSize: "10px", letterSpacing: ".5px", color }}
								>
									<Icon placement="left" iconName="solid-trophy" />
									Goal Reached
								</Text>
							)}
						</Pane>
					)}
				</Pane>
				<FormattedCurrency
					className="f-5 ml-4 flex-none fwb"
					value={amount}
					precision={0}
					convertFromInteger
					currencyCode={currency}
					style={{ color }}
				/>
			</Pane>
			{comment && (
				<Text
					className={cx({ "ml-7": !supporterView }, { "pl-3": !supporterView })}
					variant="light"
					style={{ fontStyle: "italic" }}
					as="blockquote"
				>
					{comment}
				</Text>
			)}
		</Pane>
	)
}

export default Donor
