import React from "react"
import Pane from "@webconnex/rainbow/layout/pane"
import { Donors, Gallery, IntroSupporter } from "../../../components"
import VideoPlayer from "../../../components/2r-video-player/video-player"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"
import { S3ImageType } from "@webconnex/rainbow/types/image"

interface Props {
	pageSlug: string
	intro: string
	videoURL: string
	pageName: string
	firstName: string
	lastName: string
	currency: SupportedCurrencyCodes
	galleryImages: S3ImageType[]
	profileImage: S3ImageType
	color?: string
	supporterView?: boolean
	showDonors: boolean
}

const Info: React.FC<Props> = ({
	pageSlug,
	intro,
	videoURL,
	pageName,
	firstName,
	lastName,
	color,
	currency,
	galleryImages,
	profileImage,
	supporterView,
	showDonors,
}) => {
	return (
		<Pane className="flex-grow-1 lg_pr-8 xl_mr-5" spacing={11}>
			<span className="dn lg_db mb-4 mt--4">
				<IntroSupporter
					firstName={firstName}
					lastName={lastName}
					pageName={pageName}
					pageSlug={pageSlug}
					description={intro}
					profileImage={profileImage}
				/>
			</span>
			{videoURL && <VideoPlayer url={videoURL} />}
			{showDonors && (
				<Donors
					supporterView={supporterView}
					pageSlug={pageSlug}
					color={color}
					currency={currency}
				/>
			)}
			<Gallery images={galleryImages} />
		</Pane>
	)
}

export default Info
