import React, { SyntheticEvent } from "react"

import Title from "@webconnex/rainbow/typography/title"
import Pane from "@webconnex/rainbow/layout/pane"
import Button from "@webconnex/rainbow/components/button"
import List from "@webconnex/rainbow/components/list"
import Icon from "@webconnex/rainbow/components/icon"
import SolidMail from "@webconnex/rainbow/icons/solid-mail"
import SolidHyperlink from "@webconnex/rainbow/icons/solid-hyperlink"

import { copyTextToClipboard, generateStyles, networkColors } from "./utils"

import {
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	EmailShareButton,
	TwitterIcon,
} from "react-share"

interface Props {
	subject: string
}
const Share: React.FC<Props> = ({ subject }) => {
	const copyLink = (e: SyntheticEvent) => {
		if (!(e.target instanceof HTMLButtonElement)) {
			return
		}
		copyTextToClipboard(
			e.target.dataset.link || window.location.origin + window.location.pathname
		)
	}
	const shareUrl = window.location.origin + window.location.pathname

	return (
		<Pane className="mt-6">
			<Title size={2} className="fwb">
				Share
			</Title>
			<List xs="row-wrap" sm="row" className="mt-4" spacing={2}>
				<FacebookShareButton url={shareUrl}>
					<Button
						style={generateStyles("fb")}
						className="pl-1 pr-3 mb-1"
						size="small"
					>
						<FacebookIcon
							size={28}
							iconBgStyle={{ fill: networkColors["fb"] }}
							logoFillColor="#fff"
						/>{" "}
						Facebook
					</Button>
				</FacebookShareButton>
				<TwitterShareButton url={shareUrl}>
					<Button
						style={generateStyles("tw")}
						className="pl-1 pr-3 mb-1"
						size="small"
					>
						<TwitterIcon
							size={28}
							iconBgStyle={{ fill: networkColors["tw"] }}
							logoFillColor="#fff"
						/>{" "}
						Twitter
					</Button>
				</TwitterShareButton>
				<EmailShareButton
					url={shareUrl}
					openWindow={true}
					subject={subject}
					body="Support this campaign!"
				>
					<Button style={generateStyles()} className="ph-3 mb-1" size="small">
						<Icon icon={SolidMail} placement="left" />
						Email
					</Button>
				</EmailShareButton>
				<Button
					style={generateStyles()}
					className="ph-3 mb-1"
					size="small"
					onClick={copyLink}
					data-link={shareUrl}
				>
					<Icon icon={SolidHyperlink} placement="left" />
					Copy Link
				</Button>
			</List>
		</Pane>
	)
}
export default Share
