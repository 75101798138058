import {
	required,
	creditCard,
	expiration,
	cvv,
	email,
} from "@webconnex/rainbow/validators"
import { ErrorMessages } from "../../types/error"
import { submitRegistration } from "../../utils/director-integration/converter"
import { DonationFormValues } from "../../types/donation-form"
import { assemblePublishedURL } from "@webconnex/rainbow/utils/url/published-urls/assemble"
export const validations = {
	amount: {
		presence: {
			allowEmpty: false,
			message: "Please enter an amount.",
		},
		numericality: {
			greaterThan: 0,
			message: "Please enter an amount greater than zero.",
		},
	},
	firstName: () =>
		required({
			required: "Please enter the first name on your credit card.",
		}),
	lastName: () =>
		required({
			required: "Please enter the last name on your credit card.",
		}),
	cardNumber: {
		...creditCard({
			too_short: ErrorMessages.cardNumber,
			too_long: ErrorMessages.cardNumber,
			only_numbers: ErrorMessages.cardNumber,
		}),
		...required({ required: "Please enter your credit card number." }),
	},
	addressLine1: () =>
		required({
			required: "Please enter your address.",
		}),
	expiration: {
		...expiration({
			too_short: "Please enter valid expiration date.",
		}),
		...required({
			required: "Please enter the expiration date of your credit card.",
		}),
	},
	cvv: {
		...cvv({
			too_short: ErrorMessages.cvv,
			too_long: ErrorMessages.cvv,
			not_number: ErrorMessages.cvv,
		}),
		...required({ required: "Please enter your CVV code." }),
	},
	addressCity: () =>
		required({
			required: "Please enter your city.",
		}),

	addressZIP: () =>
		required({
			required: "Please enter your zip code.",
		}),
	email: {
		...email({
			invalid_email_format: ErrorMessages.email,
			too_short: ErrorMessages.email,
			too_long: ErrorMessages.email,
		}),
		...required({ required: "Please enter your email address." }),
	},
	displayName: () =>
		required({
			required: "Please enter a display name.",
		}),
}

export const submitDonationForm = async (
	values: DonationFormValues,
	supporterName: string = "",
	setError: any,
	publishedPagePath: string,
	domain: string
) => {
	const { data, success, hash } = await submitRegistration(
		values,
		supporterName,
		publishedPagePath
	)
	const errMsg = "Uh oh, something went wrong... Unable to process transaction."

	if (!data || !success || !hash) {
		setError(data?.errorMessage || errMsg)
		return
	}

	const event = new CustomEvent("wbcxCheckpointOrder")
	document.body.dispatchEvent(event)

	let receiptURL
	if (process.env.NODE_ENV === "development") {
		receiptURL = `${process.env.REACT_APP_BACON_CLIENT_URL}/${hash}/receipt`
	} else {
		// Assemble published url.
		const publishedURL = assemblePublishedURL(
			publishedPagePath,
			domain,
			process.env.NODE_ENV,
			process.env.REACT_APP_BACON_CLIENT_URL
		)

		// Strip the path from the url.
		const [urlWithoutPath] = publishedURL.split("/crowd/")

		// Add the receipt path.
		receiptURL = `${urlWithoutPath}/${hash}/receipt`
	}

	// Redirect to receipt page.
	window.location.href = receiptURL
	return
}
