import { useQuery } from "@apollo/react-hooks"
import { loader } from "graphql.macro"
import { parseP2PUrl } from "../../utils/url"
import { fetchMore } from "../../utils/apollo"

const getDonationsGQL = loader("../../graphql/queries/donations.graphql")
const queryName = "orders"

function useDonations() {
	const { pathParams } = parseP2PUrl()

	const result = useQuery(getDonationsGQL, {
		variables: {
			...pathParams,
			sort: "date_updated",
			direction: "desc",
		},
		fetchPolicy: "cache-and-network",
	})

	// Setup fetchMore function.
	const fetchMoreDonations = () =>
		fetchMore(result.fetchMore, queryName, result)

	return { fetchMore: fetchMoreDonations, result }
}

export default useDonations
