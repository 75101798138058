import React from "react"
import Button from "../button/button"
import Divider from "@webconnex/rainbow/components/divider"
import Title from "@webconnex/rainbow/typography/title"
import List from "@webconnex/rainbow/components/list"
import Pane from "@webconnex/rainbow/layout/pane"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"
import { globalDefaultAccentColor } from "../../utils"
import Donor from "./donor"
import { usePaginatedSupporters, useDonations } from "../../hooks"
import { mapSupportersToOrders } from "../../utils/donation"
import { DonationPublic } from "../../types/donation"
import { PaginatedDataType } from "../../types/common"

interface Props {
	pageSlug: string
	currency: SupportedCurrencyCodes
	color?: string
	supporterView?: boolean
}

const Donors: React.FC<Props> = ({
	pageSlug,
	color = globalDefaultAccentColor,
	currency,
	supporterView,
}) => {
	const { result: supporterResult } = usePaginatedSupporters({ limit: 250 })
	const { fetchMore, result: donationsResult } = useDonations()

	const paginatedDonations = donationsResult?.data?.response
		?.orders as PaginatedDataType
	const loading = supporterResult.loading || donationsResult?.loading

	if (loading && !paginatedDonations) {
		return (
			<div>
				<Title size={2}>Loading Donations...</Title>
			</div>
		)
	}

	if (!paginatedDonations) {
		return (
			<div>
				<br />
			</div>
		)
	}

	paginatedDonations.records = mapSupportersToOrders(
		paginatedDonations.records,
		supporterResult.data?.response?.supporters?.records
	)

	const {
		totalRecords = 0,
		totalPages = 0,
		currentPage = 1,
		records: donations = [] as DonationPublic[],
	} = paginatedDonations!
	const showMoreButton: boolean = currentPage < totalPages

	const donorList = donations.map((donor: DonationPublic, i: number) => (
		<Donor
			supporterView={supporterView}
			pageSlug={pageSlug}
			donor={donor}
			index={i}
			color={color}
			currency={currency}
			key={`donor-${i}`}
		/>
	))

	return (
		<Pane className="mb-8">
			<Title size={2} className="fwb">
				{totalRecords} Donations
			</Title>
			<List divider spacing={5} className="mt-5" childClassName="mb-5">
				{donorList}
			</List>
			{showMoreButton && (
				<>
					<Divider className="mb-5 " />
					<Button
						emphasis="low"
						variant="primary"
						className="pa-0 fwb mb-5"
						style={{ color }}
						onClick={fetchMore}
					>
						Show More
					</Button>
				</>
			)}
		</Pane>
	)
}

export default Donors
