import Big from "big.js"
import React, {
	ChangeEvent,
	SyntheticEvent,
	useCallback,
	useEffect,
	useState,
} from "react"

import currencies from "@webconnex/rainbow/_shared/currency/currencies.json"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"
import Icon from "@webconnex/rainbow/components/icon"
import Tooltip from "@webconnex/rainbow/components/tooltip"
import { InputAddress } from "@webconnex/rainbow/forms/input-address"
import InputCheckbox, {
	InputCheckboxGroup,
} from "@webconnex/rainbow/forms/input-checkbox"
import { InputCreditCardField } from "@webconnex/rainbow/forms/input-credit-card"
import { InputPhoneField } from "@webconnex/rainbow/forms/input-phone"
import { InputShortDateField } from "@webconnex/rainbow/forms/input-short-date"
import { InputTextField } from "@webconnex/rainbow/forms/input-text"
import { InputTextareaField } from "@webconnex/rainbow/forms/input-textarea"
import useForm from "@webconnex/rainbow/hooks/use-form"
import QuestionCircle from "@webconnex/rainbow/icons/question-circle"
import Grid, { GridColumn } from "@webconnex/rainbow/layout/grid"
import Pane from "@webconnex/rainbow/layout/pane"
import Text from "@webconnex/rainbow/typography/text"
import Title from "@webconnex/rainbow/typography/title"
import { formatCurrency } from "@webconnex/rainbow/utils/number-format"
import { DonationFormValues } from "../../types/donation-form"
import { globalDefaultAccentColor } from "../../utils"
import Button from "../button/button"
import SuggestedDonation from "../suggested-donation/suggested-donation"
import { submitDonationForm, validations } from "./utils"

interface Props {
	gatewayId: number
	supporterName?: string
	formName: string
	defaultAmount: string
	color?: string
	biggestGift: number
	currentRevenue: number
	goalRevenue: number
	coverFee?: any
	currency: SupportedCurrencyCodes
	supporterId?: number
	masterPagePath: string
	domain: string
	forceAnonymous?: boolean
	phoneNumber?: string
	optIn?: boolean
}

const DonationForm: React.FC<Props> = ({
	gatewayId,
	supporterName,
	formName,
	defaultAmount,
	color = globalDefaultAccentColor,
	biggestGift,
	currentRevenue,
	goalRevenue,
	coverFee,
	currency,
	masterPagePath,
	domain,
	supporterId = "",
	forceAnonymous = false,
}) => {
	const [error, setError] = useState("")
	const [chooseAnonymous, setChooseAnonymous] = useState(false)
	const title = supporterName ? supporterName : formName

	const submit = (submitValues: DonationFormValues) =>
		submitDonationForm(
			submitValues,
			supporterName,
			setError,
			masterPagePath,
			domain
		)

	const initialValues = {
		gatewayId: gatewayId.toString(),
		amount: defaultAmount,
		fee: "",
		total: defaultAmount,
		coverFee:
			coverFee.enabled && (coverFee.default || coverFee.required) ? "yes" : "",
		email: "",
		firstName: "",
		lastName: "",
		cardNumber: "",
		expiration: "",
		cvv: "",
		country: "US",
		addressLine1: "",
		addressLine2: "",
		addressCity: "",
		addressRegion: "",
		addressZIP: "",
		paymentMethod: "card",
		cardType: "UNKNOWN",
		comment: "",
		displayName: forceAnonymous ? "Anonymous" : "",
		anonymous: forceAnonymous ? "anonymous" : "",
		supporterId: supporterId.toString(),
		phoneNumber: "",
		optIn: "true",
	}

	const {
		inputProps,
		setValue,
		values,
		isSubmitting,
		handleSubmit,
		isValidating,
	} = useForm({
		initialValues,
		validations,
		onSubmit: submit,
	})

	const currencyPrecision = currencies[currency]?.decimalScale || 2

	const toggleCoverFee = useCallback(() => {
		setValue("coverFee", values.coverFee !== "yes" ? "yes" : "")
	}, [values.coverFee, setValue])

	const toggleOptIn = useCallback(() => {
		setValue("optIn", values.optIn !== "true" ? "true" : "")
	}, [values.optIn, setValue])

	useEffect(() => {
		let total = Big(values.amount || 0)
		let fee = Big(0)
		if (values.coverFee === "yes") {
			fee = Big(coverFee.fee)
				.times(total)
				.div(Math.pow(10, currencyPrecision))
				.round(currencyPrecision)
		}
		setValue("total", total.plus(fee).toString())
		setValue("fee", fee.toString())
	}, [values.amount, values.coverFee])

	const handleSuggestedChange = (suggestedAmount: string) => {
		setValue("amount", suggestedAmount)
	}

	const updateDisplayName = (e: ChangeEvent<HTMLInputElement>) => {
		if (forceAnonymous) {
			return
		}
		setValue(e.target.name as any, e.target.value)
		setValue("displayName", values.firstName + " " + values.lastName)
	}

	const anonomizeDisplay = (e: SyntheticEvent<HTMLInputElement>) => {
		if (e.currentTarget.checked) {
			setValue("displayName", "Anonymous")
			setChooseAnonymous(true)
		} else {
			setValue("displayName", values.firstName + " " + values.lastName)
			setChooseAnonymous(false)
		}
	}

	const toggleAnonymous = useCallback(() => {
		setValue("anonymous", values.anonymous !== "anonymous" ? "anonymous" : "")
	}, [values.anonymous, setValue])

	return (
		<form
			noValidate
			onSubmit={props => {
				handleSubmit(props)
				setError("")
			}}
			className="donation-form mb-4"
		>
			<Title size={2} style={{ color }} className="fwb">
				Donating to {title}
			</Title>
			{supporterName && (
				<Title size={6} className="fwb mt-2">
					{formName}
				</Title>
			)}
			<Title size={6} className="fwb mt-6 mb-4">
				Amount
			</Title>
			<SuggestedDonation
				biggestGift={biggestGift}
				currentRevenue={currentRevenue}
				goalRevenue={goalRevenue}
				currency={currency}
				defaultValue={defaultAmount}
				handleSuggestedChange={handleSuggestedChange}
				inputProps={inputProps.amount}
			/>
			{coverFee.enabled && (
				<InputCheckboxGroup md="row">
					<InputCheckbox
						label={
							coverFee.label ||
							"Add 3% to my donation to cover credit card fees."
						}
						className="mt-4"
						name="coverFee"
						checked={values.coverFee === "yes"}
						onChange={toggleCoverFee}
					/>
					{coverFee.tooltip && (
						<Tooltip text={coverFee.tooltip} style={{ marginTop: "16px" }}>
							<Button emphasis="low" iconOnly>
								<Icon icon={QuestionCircle} />
							</Button>
						</Tooltip>
					)}
				</InputCheckboxGroup>
			)}
			<Title size={6} className="fwb mt-6 mb-4">
				Credit Card
			</Title>
			<Grid className="mb-2">
				<GridColumn sm={6}>
					<InputTextField
						required
						placeholder="First Name"
						{...inputProps.firstName}
						onBlur={updateDisplayName}
					/>
				</GridColumn>
				<GridColumn sm={6}>
					<InputTextField
						required
						placeholder="Last Name"
						{...inputProps.lastName}
						onBlur={updateDisplayName}
					/>
				</GridColumn>
			</Grid>
			<InputCreditCardField
				required
				data-private={true}
				placeholder="Card Number"
				showCardLogo
				{...inputProps.cardNumber}
			/>
			<Grid className="mt-5">
				<GridColumn md={6}>
					<InputShortDateField
						required
						data-private={true}
						{...inputProps.expiration}
					/>
				</GridColumn>
				<GridColumn md={6}>
					<InputTextField
						required
						data-private={true}
						maxLength={4}
						placeholder="CVV"
						{...inputProps.cvv}
					/>
				</GridColumn>
			</Grid>
			<Title size={6} className="fwb mt-6 mb-4">
				Billing Address
			</Title>
			<InputAddress inputProps={inputProps} />
			<InputTextField
				required
				className="mt-5"
				placeholder="Email"
				{...inputProps.email}
			/>
			<InputPhoneField
				className="mt-5"
				required={false}
				placeholder="Mobile Number (optional)"
				{...(inputProps.phoneNumber as any)}
				disabled={isSubmitting}
			/>
			<InputCheckbox
				label={
					"Yes, it's okay to send me text messages including confirmations, changes, and updates. Message frequency varies. Message and data rates may apply."
				}
				className="mt-4"
				name="optIn"
				checked={values.optIn === "true"}
				onChange={toggleOptIn}
			/>
			<Title size={6} className="fwb mt-6 mb-4">
				Your Display Information
			</Title>
			<InputTextField
				placeholder="Display Name"
				required
				{...inputProps.displayName}
				disabled={forceAnonymous || chooseAnonymous}
			/>
			<InputTextareaField
				placeholder="Leave a comment (optional)"
				className="mt-4"
				{...inputProps.comment}
			/>
			<InputCheckbox
				label="Keep my name anonymous"
				className="mt-4"
				{...inputProps.anonymous}
				value="anonymous"
				checked={values.anonymous === "anonymous"}
				onClick={anonomizeDisplay}
				onChange={toggleAnonymous}
				disabled={forceAnonymous}
			/>
			<input type="hidden" name="supporterId" value={values.supporterId} />
			{error && (
				<Pane className="mt-3">
					<Text variant="danger">{error}</Text>
				</Pane>
			)}
			<Button
				style={{
					background: color,
					border: "none",
					minWidth: "140px",
				}}
				className="mt-6 fwb white w-100 md_w-auto"
				size="default"
				type="submit"
				disabled={isSubmitting || isValidating}
				loading={isSubmitting}
			>
				Charge{" "}
				{!isNaN(parseFloat(values.total || defaultAmount)) &&
					formatCurrency(
						parseFloat(values.total || defaultAmount),
						currency,
						"$,.2f"
					).replace(".00", "")}
			</Button>
		</form>
	)
}
export default DonationForm
