import { WbcxURL } from "@webconnex/rainbow/utils/url"

export const getSupporterIdFromPath = (slug: string) => {
	const [, supporterId] = window.location.pathname.split(`${slug}/fundraiser/`)

	if (!supporterId) {
		return undefined
	}

	return Number(supporterId)
}

export const parseP2PUrl = (href?: string) => {
	const {
		pageSlug: slug,
		type,
		domain = "",
		subdomain = "",
	} = WbcxURL.parsePublishedURL(
		href
			? href.replace(/\.([^.]+?)\.webconnex\.io/, "")
			: window.location.href.replace(/\.([^.]+?)\.webconnex\.io/, "")
	)

	let p2pSlug = !slug?.includes("crowd/") ? "crowd/" + slug : slug
	// login, sign-up and individual supporter,
	// should use the master page slug they are related to
	p2pSlug = p2pSlug
		.replace("/login", "")
		.replace("/sign-up", "")
		.replace(/\/fundraiser\/\d+/, "")

	const pageSlug = p2pSlug.split("crowd/")[1]
	const supporterId = getSupporterIdFromPath(pageSlug)
	return {
		pathParams: {
			p2pSlug,
			type,
			domain,
			subdomain,
			supporterId,
		},
		pageSlug,
	}
}

export const pagePathToEnv = (
	path: string,
	supporterId?: number,
	env?: string,
	box?: string
): string => {
	const processEnv =
		window.location.href.indexOf("webconnex.io") !== -1
			? "dotio"
			: env || process.env.NODE_ENV

	const { pathParams } = parseP2PUrl("https://" + path)

	if (processEnv === "development") {
		return supporterId
			? `http://localhost:4000/${pathParams.p2pSlug}/fundraiser/${supporterId}?product=${pathParams.domain}&subdomain=${pathParams.subdomain}&form=${pathParams.p2pSlug}`
			: `http://localhost:4000/${pathParams.p2pSlug}?product=${pathParams.domain}&subdomain=${pathParams.subdomain}&form=${pathParams.p2pSlug}`
	}

	let compiledPath = "https://" + path

	if (processEnv === "dotio") {
		// finding the specific environment we are using
		const matches = window.location.hostname.match(/\.([^.]+?)\.webconnex\.io/)
		// if an environment is found use it, if not use what's passed in
		const testEnv = matches && matches[1] ? matches[1] : box || ""
		compiledPath = `https://${pathParams.subdomain}.${pathParams.domain}.${testEnv}.webconnex.io/${pathParams.p2pSlug}`
	}

	if (supporterId) {
		compiledPath += `/fundraiser/${supporterId}`
	}
	return compiledPath
}
