import { WbcxURL } from "@webconnex/rainbow/utils/url"

interface Session {
	token: string
	exp: number
	data: any
}

interface GetSession {
	session: Session
	loggedIn: boolean
}

const useAuth = (storageItem: string) => {
	const exchangeToken = async (mutation: any) => {
		const parsedURL = new WbcxURL(window.location.href, true)
		if (!parsedURL?.query?.token) {
			return
		}
		// we need to get the exchange query into the session to be used
		// in the apollo header
		setSession(parsedURL.query.token, 10)

		const redirectURL = parsedURL?.query?.redirectUrl || ""

		const { data } = await mutation()

		if (data?.response?.success && data?.response?.token) {
			setSession(data.response.token.token, data.response.token.ttl)
			return { token: data.response.token, redirectURL }
		} else {
			window.location.replace(redirectURL + "#error")
		}
	}

	const setSession = (token: string, ttl: number, data?: any): void => {
		// assumes ttl is in seconds
		localStorage.setItem(
			storageItem,
			JSON.stringify({
				token,
				exp: Date.now() + ttl * 1000,
				data,
			})
		)
	}
	const updateSession = (token?: string, ttl?: number, data?: any): void => {
		// assumes ttl is in seconds

		let {
			session: { token: newToken, exp, data: newData },
		} = getSession()
		let newTtl = (exp - Date.now()) / 1000

		if (token) {
			newToken = token
		}
		if (data) {
			newData = data
		}
		if (ttl) {
			newTtl = ttl
		}
		setSession(newToken, newTtl, newData)
	}

	const logOut = () => {
		setSession("", 0, {})
		location.reload()
	}

	const getSession = (): GetSession => {
		const sessionString = localStorage.getItem(storageItem)
		const sess: Session = sessionString ? JSON.parse(sessionString) : {}
		const logIn: boolean = !!sess.token && sess.exp > Date.now()
		return { session: sess, loggedIn: logIn }
	}

	return {
		exchangeToken,
		setSession,
		logOut,
		getSession,
		updateSession,
		...getSession(),
	}
}
export default useAuth
