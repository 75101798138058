import React from "react"
import styles from "./intro-supporter.module.scss"
import Link from "@webconnex/rainbow/typography/link"
import Text from "@webconnex/rainbow/typography/text"
import Title from "@webconnex/rainbow/typography/title"
import Pane from "@webconnex/rainbow/layout/pane"
import { sanitizer } from "@webconnex/rainbow/utils/security"
import { globalDefaultAccentColor } from "../../utils"
import { toPage } from "../../utils/navigation"
import { S3ImageType } from "@webconnex/rainbow/types/image"
import { S3Image } from "@webconnex/rainbow/components/s3-image"

const s3ImageServiceURL =
	process.env.S3_IMAGE_SERVICE_URL ||
	process.env.REACT_APP_S3_IMAGE_SERVICE_URL ||
	"localhost"

interface Props {
	color?: string
	description: string
	firstName: string
	lastName: string
	pageName: string
	pageSlug: string
	profileImage: S3ImageType
}

const IntroSupporter: React.FC<Props> = ({
	color = globalDefaultAccentColor,
	description,
	firstName,
	lastName,
	profileImage,
	pageName,
	pageSlug,
}) => {
	// converting newlines to <p> tags
	description = description.replace(/\n{2}/g, "</p><p>")
	description = description.replace(/\n/g, "<br />")
	description = "<p>" + description + "</p>"

	return (
		<Pane lg="row" className="mb-6">
			{profileImage && (
				<S3Image
					s3ImageServiceURL={s3ImageServiceURL}
					className={styles.profileImage}
					image={profileImage}
				/>
			)}
			<Pane spacing={2}>
				<Pane className="tc lg_tl">
					<Title size={2} className="fwb mb-1">
						{firstName + " " + lastName}
					</Title>
					<Pane lg="row-wrap" spacing={1}>
						<Text size={3} variant="medium" className="mt-0">
							is raising money for{" "}
						</Text>
						<Link
							className="f-5 fwb mt-2 lg_f-2 lg_mt-0"
							variant="primary"
							style={{ color }}
							onClick={() => {
								toPage(`/${pageSlug}`)
							}}
						>
							{pageName}
						</Link>
					</Pane>
				</Pane>
				<Text
					dangerouslySetInnerHTML={{
						__html: sanitizer(description),
					}}
					as="div"
					className="f-2 lg_f-4"
				/>
			</Pane>
		</Pane>
	)
}
export default IntroSupporter
