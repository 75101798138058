import TagManager from "react-gtm-module"

export interface Fonts {
	body: {
		face: string
		isGoogle: boolean
	}
	headline: {
		face: string
		isGoogle: boolean
	}
}

export const insertGoogleFonts = (fonts: Fonts) => {
	if (!fonts.body.isGoogle && !fonts.headline.isGoogle) {
		// neither is a google font so we do nothing
		return
	}
	// we are going to have to add a stylesheet
	const newStyle = document.createElement("link")
	newStyle.rel = "stylesheet"
	newStyle.href = "https://fonts.googleapis.com/css?family="

	if (fonts.body.face === fonts.headline.face) {
		// we only need to add one font
		newStyle.href += escape(fonts.body.face.replace(/[^a-z0-9\s]/gi, ""))
	} else {
		// we may need to add more than one font
		if (fonts.body.isGoogle) {
			newStyle.href += escape(fonts.body.face.replace(/[^a-z0-9\s]/gi, ""))
		}
		if (fonts.body.isGoogle && fonts.headline.isGoogle) {
			newStyle.href += "|"
		}
		if (fonts.headline.isGoogle) {
			newStyle.href += escape(fonts.headline.face.replace(/[^a-z0-9\s]/gi, ""))
		}
	}
	newStyle.href += "&display=swap"
	document.getElementsByTagName("head")[0].appendChild(newStyle)
}

export const insertMeta = (settings: any) => {
	const meta = [
		{
			name: "og:url",
			content: settings?.social?.url
				? settings?.social?.url
				: settings?.url || window.location,
		},
		{ name: "og:type", content: "website" },
	]

	if (settings?.social?.title) {
		meta.push({ name: "og:title", content: settings.social.title })
	}
	if (settings?.social?.description) {
		meta.push({
			name: "og:description",
			content: settings.social.description,
		})
	}

	if (settings?.social?.image?.fullPath) {
		meta.push({ name: "og:image", content: settings.social.image.fullPath })
		if (settings?.social?.image?.width && settings?.social?.image?.height) {
			meta.push({
				name: "og:image:width",
				content: settings.social.image.width,
			})
			meta.push({
				name: "og:image:height",
				content: settings.social.image.height,
			})
		}
	}
	if (settings?.social?.videoURL) {
		meta.push({ name: "og:video", content: settings.social.videoURL })
	}

	meta.forEach((item: any) => {
		const newMeta = document.createElement("meta")
		newMeta.name = item.name
		newMeta.content = item.content
		document.getElementsByTagName("head")[0].appendChild(newMeta)
	})
}

export const insertTracking = (settings: any) => {
	if (settings?.googleTagManagerEnable && settings?.tagMangerCode) {
		TagManager.initialize({ gtmId: settings.tagMangerCode })
	}
}
