import registrationTemplate, { anonymousItem } from "./registration-template"
import { Field, Item, RegistrationPrivate } from "../../types/registration"
import { formatTypeAndLast4 } from "@webconnex/rainbow/utils/credit-card/helper-methods"
import { DonationFormValues } from "../../types/donation-form"
import { register } from "../../to-rainbow/register"
import { encrypt } from "@webconnex/rainbow/utils/tokenize/tokenize"
import { RegisterData } from "../../to-rainbow/register-error"

type FieldKey =
	| "donation_amount"
	| "displayName"
	| "anonymousName"
	| "supporterName"
	| "donationComment"

const fieldKeys: Record<string, FieldKey> = {
	donation: "donation_amount",
	displayName: "displayName",
	anonymous: "anonymousName",
	supporterName: "supporterName",
	donationComment: "donationComment",
}

type ItemPath = "registrants.donation_amount" | "registrants.anonymousName"

const itemPaths: Record<string, ItemPath> = {
	donation: "registrants.donation_amount",
	anonymous: "registrants.anonymousName",
}

type SubmitReturn = {
	success: boolean
	hash?: string
	data?: RegisterData
	error?: string
}
export const submitRegistration = async (
	p2pValues: DonationFormValues,
	supporterName: string,
	publishedPagePath: string
): Promise<SubmitReturn> => {
	try {
		const registration = await p2pToRegistration(p2pValues, supporterName)
		const { hash, data } = await register(registration, publishedPagePath)

		return {
			success: true,
			hash,
			data,
		}
	} catch (error) {
		return { success: false, error }
	}
}

export const p2pToRegistration = async (
	p2pValues: DonationFormValues,
	supporterName: string
): Promise<RegistrationPrivate> => {
	const registration = { ...registrationTemplate } as any
	const billing = registration.billing
	// P2P form will only always have 1 registrant.
	const registrant = registration.registrants[0]
	const registrantFields = registrant.$fields
	const registrantItems = registrant.$items

	const donationField =
		registrantFields[getFieldIndex(registrantFields, fieldKeys.donation)]
			.$repeater![0]
	const displayField =
		registrantFields[getFieldIndex(registrantFields, fieldKeys.displayName)]
	const anonymousField =
		registrantFields[getFieldIndex(registrantFields, fieldKeys.anonymous)]
	const donationItem =
		registrantItems[getItemIndex(registrantItems, itemPaths.donation)]
	const supporterNameField =
		registrantFields[getFieldIndex(registrantFields, fieldKeys.supporterName)]
	const donationCommentField =
		registrantFields[getFieldIndex(registrantFields, fieldKeys.donationComment)]

	registrant.$total = p2pValues.total
	donationField.amount.$value = p2pValues.amount
	donationField.fund.$value = p2pValues.supporterId
	displayField.$value = p2pValues.displayName
	anonymousField.$value = p2pValues.anonymous === "anonymous"
	donationItem.amount = p2pValues.total
	donationItem.deductible = p2pValues.total
	donationItem.category = p2pValues.supporterId
	supporterNameField.$value = supporterName
	donationCommentField.$value = p2pValues.comment

	if (p2pValues.coverFee === "yes") {
		donationField.coverFee = {
			$amount: p2pValues.fee,
			$type: "checkbox",
			$value: true,
		}
	}

	// If anonymous, add anonymous item to items.
	if (p2pValues.anonymous === "anonymous") {
		registrantItems.push(anonymousItem)
	}

	// Modify the registration template billing.
	billing.paymentGatewayId = Number(p2pValues.gatewayId)
	billing.name.first = p2pValues.firstName
	billing.name.last = p2pValues.lastName

	billing.card.cardNumber = formatTypeAndLast4(p2pValues.cardNumber)

	const [expMonth, expYear] = [
		Number(p2pValues.expiration.slice(0, 2)),
		Number(p2pValues.expiration.slice(2)),
	]
	billing.card.expMonth = expMonth
	billing.card.expYear = expYear
	billing.card.cvvCode = p2pValues.cvv
	billing.address.street1 = p2pValues.addressLine1
	billing.address.city = p2pValues.addressCity
	billing.address.state = p2pValues.addressRegion
	billing.address.postalCode = p2pValues.addressZIP
	billing.address.country = p2pValues.country

	const cardNumberEnc = encrypt(
		process.env.REACT_APP_TOKEN_PUBLIC_KEY!,
		p2pValues.cardNumber
	)
	const cvvCodeEnc =
		p2pValues.cvv &&
		encrypt(process.env.REACT_APP_TOKEN_PUBLIC_KEY!, p2pValues.cvv)
	const billingToken = await tokenize(
		process.env.REACT_APP_TOKEN_API!,
		cardNumberEnc,
		cvvCodeEnc
	)

	if (!billingToken) {
		const productionMode = process.env.NODE_ENV === "production"
		const msg = !productionMode
			? "Dev Error: Make sure tokenize api is running."
			: "Uh oh, something went wrong... Unable to process transaction."
		throw new Error(msg)
	}

	billing.token = billingToken
	billing.email = p2pValues.email
	billing.phone = p2pValues.phoneNumber
	if (billing.phone) {
		billing.phoneType = "MOBILE"
	}

	return registration
}

const getFieldIndex = (fields: Field[], fieldKey: FieldKey) => {
	return fields.findIndex(field => field.$key === fieldKey)
}

const getItemIndex = (items: Item[], itemPath: ItemPath) => {
	return items.findIndex(item => item.path === itemPath)
}

export async function tokenize(
	tokenAPI: string,
	encrypted: string,
	encryptedCvv?: string
): Promise<string> {
	const response = await fetch(tokenAPI + "/tokenize", {
		method: "POST",
		body: JSON.stringify({
			encrypted,
			encryptedCvv,
		}),
	})

	if (!response.ok) {
		return ""
	}

	const data = await response.json()
	return data.token
}
