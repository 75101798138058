import React from "react"
import Pane from "@webconnex/rainbow/layout/pane"
import { Donors, Gallery, IntroMaster, Supporters } from "../../../components"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"

interface Props {
	pageSlug: string
	headline: string
	description: string
	currency: SupportedCurrencyCodes
	images: any[]
	showSupporters: boolean
	showDonors: boolean
	videoURL?: string
	color?: string
	findAction(): void
}

const Info: React.FC<Props> = ({
	videoURL,
	headline,
	description,
	color,
	currency,
	images,
	findAction,
	showSupporters,
	showDonors,
	pageSlug,
}) => {
	return (
		<Pane className="flex-grow-1 lg_pr-8 xl_mr-5" spacing={10}>
			<IntroMaster
				headline={headline}
				description={description}
				videoURL={videoURL}
			/>
			{showSupporters && (
				<Supporters pageSlug={pageSlug} findAction={findAction} color={color} />
			)}
			{showDonors && (
				<Donors pageSlug={pageSlug} color={color} currency={currency} />
			)}
			<Gallery images={images} />
		</Pane>
	)
}

export default Info
