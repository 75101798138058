import React from "react"

import cx from "classnames"
import ReactPlayer from 'react-player'

import styles from "./video-player.module.scss"

interface Props {
  url: string
  className?: string
}

const VideoPlayer: React.FC<Props> = ({
  className,
  url,
}) => {

	return (

    <div className={cx(className, styles.wrapper)}>
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        className={styles.player}
        controls={true}
        light={true}
        playing={true}
        config={{
          youtube: {
            playerVars: {
              controls: 0,
              rel: 0,
            }
          },
          vimeo: {
            playerOptions:{
              byline: false,
              portrait: false,
              responsive: false,
              title: false,
            }
          },
          wistia: {
            options: {
              playbar: false,
              playButton: true,
              settingsControl: false,
            }
          }
        }}
      />
    </div>
	)
}
export default VideoPlayer
