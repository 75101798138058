const currencies = {
	EUR: {
		decimalSeparator: ",",
		thousandSeparator: ".",
		prefix: "€",
		suffix: "",
		decimalScale: 2,
	},
	BRL: {
		decimalSeparator: ",",
		thousandSeparator: ".",
		prefix: "R$",
		suffix: "",
		decimalScale: 2,
	},
	NOK: {
		decimalSeparator: ",",
		thousandSeparator: ".",
		prefix: "kr",
		suffix: "",
		decimalScale: 2,
	},
	SEK: {
		decimalSeparator: ",",
		thousandSeparator: ".",
		prefix: "kr",
		suffix: "",
		decimalScale: 2,
	},
	DKK: {
		decimalSeparator: ",",
		thousandSeparator: ".",
		prefix: "kr",
		suffix: "",
		decimalScale: 2,
	},
	USD: {
		decimalSeparator: ".",
		thousandSeparator: ",",
		prefix: "$",
		suffix: "",
		decimalScale: 2,
	},
	SGD: {
		decimalSeparator: ".",
		thousandSeparator: ",",
		prefix: "$",
		suffix: "",
		decimalScale: 2,
	},
	GBP: {
		decimalSeparator: ".",
		thousandSeparator: ",",
		prefix: "£",
		suffix: "",
		decimalScale: 2,
	},
	MXN: {
		decimalSeparator: ".",
		thousandSeparator: ",",
		prefix: "$",
		suffix: "",
		decimalScale: 2,
	},
	AUD: {
		decimalSeparator: ".",
		thousandSeparator: ",",
		prefix: "$",
		suffix: "",
		decimalScale: 2,
	},
	CAD: {
		decimalSeparator: ".",
		thousandSeparator: ",",
		prefix: "$",
		suffix: "",
		decimalScale: 2,
	},
	CHF: {
		decimalSeparator: ",",
		thousandSeparator: "'",
		prefix: "CHF\u00a0",
		suffix: "",
		decimalScale: 2,
	},
	NZD: {
		decimalSeparator: ".",
		thousandSeparator: ",",
		prefix: "$",
		suffix: "",
		decimalScale: 2,
	},
	CNY: {
		decimalSeparator: ".",
		thousandSeparator: ",",
		prefix: "¥",
		suffix: "",
		decimalScale: 2,
	},
}
export default currencies
