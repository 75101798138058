import React, { useState } from "react"
import Text from "@webconnex/rainbow/typography/text"
import Pane from "@webconnex/rainbow/layout/pane"
import { Button } from ".."

type Props = {
	genericError?: boolean
	message?: string
	secondaryMessage?: string
}
const genericFirstMessage = "Ooops!! An unexpected error has occurred."
const genericSecondMessage =
	"Why not try refreshing your page? Or you can contact us if the problem persists. "

export const PageError = ({
	message = genericFirstMessage,
	genericError = false,
	secondaryMessage,
}: Props) => {
	return (
		<Pane className="mv-8 lg_w-50 mh-auto pa-4 bg-red-10">
			<Text variant="danger" className="f-7 mb-4">
				{message}
			</Text>

			{(secondaryMessage || genericError) && (
				<Text>{secondaryMessage || genericSecondMessage}</Text>
			)}
		</Pane>
	)
}

export const DevPageError = ({
	message,
	error,
}: {
	message: string
	error: any
}) => {
	const [showProdError, setShowProdError] = useState(false)
	return (
		<Pane className="mv-1 mh-3 pa-4 ">
			{!showProdError ? (
				<>
					<Text variant="danger" className="f-8">
						{message}
					</Text>
					<Text variant="danger" className="f-2">
						This error only shows in non production mode.
					</Text>
					<Button
						className="mv-3"
						style={{ width: "fit-content" }}
						onClick={() => setShowProdError(true)}
					>
						See Production Error
					</Button>
					<pre>{JSON.stringify(error, null, 2)}</pre>
				</>
			) : (
				<>
					<PageError genericError />
					<Button
						className="mv-5 mh-4"
						style={{ width: "fit-content" }}
						onClick={() => setShowProdError(false)}
					>
						See Dev Error
					</Button>
				</>
			)}
		</Pane>
	)
}
