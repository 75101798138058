import React, { useEffect } from "react"
import { useLazyQuery } from "@apollo/react-hooks"
import { loader } from "graphql.macro"
import { QueryResult } from "@apollo/react-common"
import { PublishedPageParams } from "../types/published-page"
import { DevPageError, PageError } from "../components/page-error/page-error"
import { parseP2PUrl } from "../utils/url"
import { toPage } from "../utils/navigation"
import { getCreditCardGateway, initializeSCA } from "../to-rainbow/register"

type PageResultType = QueryResult<any, PublishedPageParams>
type ContextType = {
	gatewayId: number
	pageSlug: string
	pathParams: PublishedPageParams
	result: PageResultType
	onSupporterPage?: boolean
	refetch: () => void
}

const PageContext = React.createContext<ContextType>({
	gatewayId: 0,
	pageSlug: "",
	pathParams: {
		type: "p2p",
		domain: "",
		subdomain: "",
		p2pSlug: "",
	},
	result: ({} as any) as PageResultType,
	refetch: () => {},
})
const getPublishedPageGQL = loader("../graphql/queries/published-page.graphql")
const getSupporterPage = loader("../graphql/queries/supporter.graphql")

function PageProvider({ ...props }: any) {
	const { pathParams, pageSlug } = parseP2PUrl()

	// If on supporter page, use supporter query.
	const gqlQuery = pathParams.supporterId
		? getSupporterPage
		: getPublishedPageGQL

	const [runQuery, result] = useLazyQuery(gqlQuery, {
		fetchPolicy: "cache-and-network",
	})

	const refetch = result.refetch

	// If there is a supporter id, but it is invalid,
	// then redirect to the master page.
	const isInvalidSupporterId =
		pathParams?.supporterId && isNaN(pathParams.supporterId)
	if (isInvalidSupporterId) {
		toPage("/" + pageSlug)
		return null
	}

	useEffect(() => {
		// Only run query, if valid supporterId.
		if (!isInvalidSupporterId && pageSlug) {
			runQuery({ variables: pathParams })
		}
	}, [pathParams.supporterId])

	if (result.loading) {
		return null
	}

	if (result.error) {
		if (process.env.NODE_ENV !== "production") {
			return (
				<DevPageError
					message={"Apollo: page query error"}
					error={result.error}
				/>
			)
		}
		return <PageError genericError />
	}

	// Load dependencies for SCA authentication.
	initializeSCA(result)

	const gateway = getCreditCardGateway(result)

	return (
		<PageContext.Provider
			value={{
				result,
				pageSlug,
				pathParams,
				onSupporterPage: pathParams.supporterId,
				refetch,
			}}
			{...props}
		/>
	)
}

function usePage() {
	const context = React.useContext<ContextType>(PageContext)

	if (context?.result === undefined) {
		throw new Error(
			`Published page context not defined. Most likely you are using the
      usePage hook without wrapping the route in the page provider.`
		)
	}

	return context
}

export { PageProvider, usePage }
