import React from "react"
import cx from "classnames"
import Box, { BoxBody } from "@webconnex/rainbow/layout/box"
import Page from "@webconnex/rainbow/layout/page"
import Pane from "@webconnex/rainbow/layout/pane"
import RainbowMap, { MapData } from "@webconnex/rainbow/components/map/map"
import Title from "@webconnex/rainbow/typography/title"
import Text from "@webconnex/rainbow/typography/text"
import styles from "./map.module.scss"
import { globalDefaultAccentColor } from "../../utils"
import { formatNumber } from "@webconnex/rainbow/utils/number-format"
import useDonations from "../../hooks/use-donations/use-donations"
import { PaginatedDataType } from "../../types/common"

interface Props {
	visits: any[]
	totalVisitors: number
	countries: number
	color?: string
}

const Map: React.FC<Props> = ({
	visits,
	totalVisitors,
	countries,
	color = globalDefaultAccentColor,
}) => {
	if (!visits && !totalVisitors && !countries) {
		// we returned no data
		return null
	}
	const { result: donationsResult } = useDonations()
	const paginatedDonations = donationsResult?.data?.response
		?.orders as PaginatedDataType

	const totalDonations = paginatedDonations?.totalRecords || 0
	const mapData: MapData = {
		visits,
	}
	return (
		<div className={cx(styles.mapWrap, "relative")}>
			<div
				className={cx(
					styles.infoBoxWrap,
					"absolute z-1 left-0 right-0 ma-auto ph-6 pv-4 lg_pa-6"
				)}
			>
				<Box className={cx(styles.infoBox)}>
					<BoxBody className="pa-4">
						<Pane spacing={2} className="pa-1">
							<Pane spacing={1}>
								<Title size={3} style={{ color }} className="fwb">
									{formatNumber(totalDonations)} Donors
								</Title>
								<Text className="fwb">
									{formatNumber(totalVisitors)} Visitors
								</Text>
							</Pane>
							{countries > 1 && (
								<Text className="mt-2">From {countries} countries</Text>
							)}
						</Pane>
					</BoxBody>
				</Box>
			</div>
			<RainbowMap data={mapData} />
		</div>
	)
}

export default Map
