import React, { useEffect, Fragment, useState, useMemo } from "react"
import Spinner from "@webconnex/rainbow/components/spinner"
import Button from "../button/button"
import Title from "@webconnex/rainbow/typography/title"
import Link from "@webconnex/rainbow/typography/link"
import List from "@webconnex/rainbow/components/list"
import Divider from "@webconnex/rainbow/components/divider"
import InputSearch from "@webconnex/rainbow/forms/input-search"
import Pagination from "@webconnex/rainbow/components/pagination"
import { SupporterType } from "../../types/supporter"
import { globalDefaultAccentColor } from "../../utils"
import { usePaginatedSupporters, useDebounce } from "../../hooks"
import { toPage } from "../../utils/navigation"
import { usePage } from "../../contexts"
import { getFullName } from "../../utils/supporters"

interface Props {
	title: string
	color?: string
}

const SupporterSearch: React.FC<Props> = ({
	title,
	color = globalDefaultAccentColor,
}) => {
	const itemsPerPage = 100
	const [currentPage, setCurrentPage] = useState(0)
	const [searchString, setSearchString] = useState("")
	const {
		result: { data, loading },
	} = usePaginatedSupporters({
		limit: itemsPerPage,
		page: currentPage,
		name: searchString,
	})
	const [offset, setOffset] = useState(0)
	const [enteredSearchString, setEnteredSearchString] = useState("")
	const { pageSlug } = usePage()
	const [allSupporters, setAllSupporters] = useState<SupporterType[]>([])

	useEffect(() => {
		const allSupps: SupporterType[] = data?.response?.supporters?.records || []
		setAllSupporters(allSupps)
	}, [data])

	const supporterList = useMemo(
		() =>
			allSupporters.map((supporter: SupporterType, i: number) => {
				const fullName = getFullName(supporter)

				return (
					<Link
						className="mv-4"
						key={`supporter-list-${i}`}
						style={{ color }}
						onClick={() =>
							toPage(`/${pageSlug}/fundraiser/${supporter.supporterId}`)
						}
						variant="primary"
					>
						{fullName}
					</Link>
				)
			}),
		[allSupporters]
	)

	return (
		<Fragment>
			<Title size={2} className="fwb mb-4 db" style={{ color }}>
				{title}
			</Title>
			<InputSearch
				placeholder="Type a name to search"
				onChange={e => {
					setEnteredSearchString(e.currentTarget?.value || "")
					if (e.currentTarget?.value === "") {
						setOffset(0)
						setCurrentPage(1)
						setSearchString("")
					}
				}}
				value={enteredSearchString}
			/>
			<Button
				variant="primary"
				emphasis="high"
				className="white fwb w-25"
				disabled={enteredSearchString === ""}
				onClick={() => {
					setCurrentPage(1)
					setSearchString(enteredSearchString)
				}}
			>
				Search
			</Button>
			<Title size={3} className="fwb mt-6 mb-4">
				Fundraising Pages
			</Title>
			<Divider className="mb-4" />
			{loading ? (
				<Spinner size={5} />
			) : (
				<List spacing={4} divider={true}>
					{supporterList.length > 0 ? supporterList : "No pages found."}
				</List>
			)}
			<Pagination
				itemsPerPage={itemsPerPage}
				total={data?.response?.supporters?.totalRecords}
				active={offset ? offset + 1 : 1}
				clickHandler={newOffset => {
					setOffset(newOffset - 1)
					setCurrentPage((newOffset - 1) / itemsPerPage + 1)
				}}
				style={{ padding: 5 }}
			/>
		</Fragment>
	)
}

export default SupporterSearch
