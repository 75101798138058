import React from "react"
import cx from "classnames"
import {
	Countdown,
	ProgressBar,
	StarterDonation,
	Share,
} from "../../../components/"
import Box from "@webconnex/rainbow/layout/box"
import Link from "@webconnex/rainbow/typography/link"
import Icon from "@webconnex/rainbow/components/icon"
import Divider from "@webconnex/rainbow/components/divider"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"
import styles from "./aside.module.scss"
import { StartDonation } from "../../../types/donation"
import { toPage } from "../../../utils/navigation"
import { globalDefaultAccentColor } from "../../../utils"

interface Props {
	currentRevenue: number
	goalRevenue: number
	currency: SupportedCurrencyCodes
	color?: string
	deadline: string
	timeZone: string
	canCreatePage?: boolean
	biggestGift: number
	generalFundId?: string
	title: string
	supporterId?: number
	hideSelection?: boolean
	startDonation: StartDonation
	pageSlug?: string
}

const Aside: React.FC<Props> = ({
	currentRevenue = 0,
	goalRevenue = 100,
	currency = "USD",
	color = globalDefaultAccentColor,
	deadline,
	timeZone,
	canCreatePage,
	biggestGift = 0,
	generalFundId,
	title,
	startDonation,
	supporterId,
	hideSelection = false,
	pageSlug,
}) => {
	return (
		<aside className={cx(styles.aside, "lg_order-2 pb-8 lg_pv-0")}>
			<div className="mb-4">
				<Box body className="mb-6">
					<div className={cx(styles.progress, "pb-5")}>
						<ProgressBar
							currentRevenue={currentRevenue}
							goalRevenue={goalRevenue}
							currency={currency}
							location="form"
							color={color}
						/>
						<Countdown deadline={deadline} timeZone={timeZone} color={color} />
					</div>
					<Divider className="mb-5 stretch-4 lg_stretch-7" />
					<StarterDonation
						color={color}
						biggestGift={biggestGift}
						currentRevenue={currentRevenue}
						goalRevenue={goalRevenue}
						currency={currency}
						generalFundId={generalFundId}
						startDonation={startDonation}
						supporterId={supporterId}
						hideSelection={hideSelection}
					/>
				</Box>
				{canCreatePage && pageSlug && (
					<Link
						variant="primary"
						style={{ color }}
						onClick={() => toPage(`/${pageSlug}/sign-up`)}
						className="fwb"
					>
						Fundraise For This Cause{" "}
						<Icon iconName="chevron-right" size="small" placement="right" />
					</Link>
				)}
				<Share subject={title} />
			</div>
		</aside>
	)
}

export default Aside
