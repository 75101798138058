import { DonationPublic } from "../types/donation"
import { SupporterType } from "../types/supporter"
import { Field } from "../types/registration"

// Maps supporters onto array of donations (orders).
export const mapSupportersToOrders = (
	donations: DonationPublic[],
	supporters: SupporterType[]
) => {
	if (!donations) {
		return []
	}
	if (!supporters) {
		return donations
	}

	donations.forEach(donation => {
		const supporterId = getSupporterIdFromDonation(donation)
		if (supporterId === 0) {
			return
		}

		const supporter = supporters?.find(supp => supp.supporterId === supporterId)

		donation.supporter = supporter
	})

	return donations
}

const getSupporterIdFromDonation = (donation: DonationPublic) => {
	// No supporter will be represented by "0".
	const noSupporterId = 0

	// P2P will only always have one registrant. Grab that registrant.
	const { $fields: fields = [] } = donation.registrants[0]

	// displayName is used in p2p forms to identify field holding the supporter id.
	// Get the value of that field, which represents the supporterId.
	// Defaults to "0".
	const donationField = getDonationField(fields)
	const donationSubFields = donationField?.$repeater![0]
	const supporterId = Number(donationSubFields?.fund?.$value || noSupporterId)

	return supporterId
}

export const getDonationField = (fields: Field[]) =>
	fields.find(field => field.$key === "donation_amount")
