import { PublishedPageParams } from "../types/published-page"
import { convertCurrencyToInteger } from "../to-rainbow/number-format"
import moment from "moment-timezone"
import Color from "color"
import { S3ImageType } from "@webconnex/rainbow/types/image"
import { UseS3Upload } from "@webconnex/rainbow/hooks/use-s3-upload"

export const globalDefaultAccentColor = "#3088F4"

export enum ErrorMessages {
	cardNumber = "Please check your card number.",
	cvv = "Please check your CVV code.",
	email = "Please check your email address.",
}

export const compilePublishedPageParams = (
	domain: string = "",
	subdomain: string = "",
	pageSlug: string = ""
): PublishedPageParams => ({
	domain,
	subdomain,
	p2pSlug: "crowd/" + pageSlug,
	type: "p2p",
})

export const parseDescription = (
	description: string = ""
): { shortDescription: string; longDescription: string } => {
	let shortDescription = description
	let longDescription = ""
	const SHORT_DESC_LENGTH = 3

	const descriptionParts = description.split("</p>")

	if (descriptionParts.length > SHORT_DESC_LENGTH) {
		shortDescription = descriptionParts.slice(0, SHORT_DESC_LENGTH).join("</p>")
		shortDescription = shortDescription + "</p>"

		longDescription = descriptionParts.splice(SHORT_DESC_LENGTH).join("</p>")
	}

	return {
		shortDescription,
		longDescription,
	}
}

export const parseIndividualImage = (image: S3ImageType) => {
	if (image.id) {
		return {
			id: image.id,
			originalFilename: image.originalFilename || "",
			s3Key: image.s3Key || "",
			delete: image.deleted || false,
		}
	}
	return {
		originalFilename: image.originalFilename,
		s3Key: image.s3Key,
	}
}

export const parseImageObject = (images: UseS3Upload | UseS3Upload[]): any => {
	if (Array.isArray(images)) {
		return images
			.map(image => parseIndividualImage(image.file))
			.filter(image => image.s3Key && image.originalFilename)
	}
	return parseIndividualImage(images.file)
}

export const parsePublishedPageResponse = (pageSnapshot: any) => {
	const { fields, design = {}, currency, formHash, settings } = pageSnapshot
	const rawP2PField = fields?.header?.children.find(
		(field: any) => field.type === "peerToPeer"
	)

	// renaming p2plabel to "name" since we do not want the  form name
	// anywhere in p2p
	const name = rawP2PField?.attributes?.p2pLabel?.attributes?.text || ""

	// the replace is a hack needed for preview environments
	const assetPath = design.assetPath.replace("dev", "staging")

	const imgUrls = rawP2PField?.attributes?.imgGallery?.attributes?.urls || []
	const parsedGallery: S3ImageType[] = Object.keys(imgUrls)
		.filter((key: string) => {
			return !!rawP2PField?.attributes?.imgGallery?.attributes?.urls[key]
		})
		.map((key: string) => {
			// We want to end up with an S3 image object for the gallery so we can
			// crop the image to a specific size. Based on the public URL
			// we can get the image key and bucket.
			// Production example: https://cdn.uploads.webconnex.com -> uploads.webconnex.com
			// We have to parse both assetPath types temporarily to support both cdn and s3.amazonaws.
			let s3Bucket = assetPath.replace("https://s3.amazonaws.com/", "")
			s3Bucket = s3Bucket.replace("https://cdn.", "")
			const matches = s3Bucket.match(/\/(\w+)\//)

			// If the match doesn't occur we don't want to be hosed so
			// we set a specific type that we can detect in the gallery and
			// parse differently. Specifically, by storing the full URL in the
			// originalFilename field.

			const type = matches ? "gallery" : "raw"
			const s3Key = matches
				? matches[1] +
				  "/" +
				  rawP2PField?.attributes?.imgGallery?.attributes?.urls[key]
				: rawP2PField?.attributes?.imgGallery?.attributes?.urls[key]
			const originalFilename = matches
				? rawP2PField?.attributes?.imgGallery?.attributes?.urls[key]
				: assetPath + rawP2PField?.attributes?.imgGallery?.attributes?.urls[key]

			if (matches) {
				s3Bucket = s3Bucket.replace(matches[0], "")
			}
			return {
				type,
				// if it's uploaded via CP spaces get converted
				// to %20 but the image service wants the space
				s3Key: unescape(s3Key),
				s3Bucket,
				originalFilename,
			}
		})

	const timeZone =
		rawP2PField.attributes.fundraisingDeadline.attributes?.tz ||
		moment.tz.guess()

	let bannerImg = rawP2PField.attributes.bannerImg.attributes.urls[0]
	if (!bannerImg?.includes("//")) {
		bannerImg = assetPath + bannerImg
	}
	const p2pField = {
		allowGoalChange: rawP2PField.attributes.allowGoalChange?.attributes.checked,
		// this is always set to true, because there is no control in the CP,
		// but it's something that we'll likely need to support in the future
		allowDeadlineChange: true,
		allowSupporters:
			rawP2PField.attributes.allowSupporterPages?.attributes.checked,
		bannerImg: bannerImg ?? "",
		contactEmail: rawP2PField.attributes.contactEmail?.attributes.email,
		externalLink: rawP2PField.attributes.externalLink?.attributes.text,
		externalLinkButtonText:
			rawP2PField.attributes.externalLinkButtonText?.attributes.text,
		deadline: rawP2PField.attributes.fundraisingDeadline.attributes.date
			? moment
					.tz(rawP2PField.attributes.fundraisingDeadline.attributes.date, "UTC")
					.format("YYYY-MM-DD")
			: "",
		timeZone,
		intro: rawP2PField.attributes.p2pDescription.attributes.text,
		goal: parseInt(
			convertCurrencyToInteger(
				rawP2PField?.attributes?.masterGoal?.attributes?.amount,
				currency
			),
			10
		),
		supporterGoal: parseInt(
			convertCurrencyToInteger(
				rawP2PField.attributes.supporterGoal.attributes.amount,
				currency
			),
			10
		),
		offlineContributions: parseInt(
			convertCurrencyToInteger(
				rawP2PField?.attributes?.offlineContributions?.attributes?.amount,
				currency
			),
			10
		),
		videoURL: rawP2PField.attributes.video.attributes.url,
		introHeadline: rawP2PField.attributes.p2pLabel.attributes.text,
		galleryImages: parsedGallery,
		signUpInstructions:
			rawP2PField?.attributes?.signupInstructions?.attributes?.text,
		anonymizeDonors:
			rawP2PField?.attributes?.anonymizeDonors?.attributes?.checked,
		showPages: rawP2PField?.attributes?.showTopPages?.attributes?.checked,
		showHistory:
			rawP2PField?.attributes?.showDonationHistory?.attributes?.checked,
		showMap: rawP2PField?.attributes?.showVisitorMap?.attributes?.checked,
		coverFee: {
			fee: rawP2PField?.attributes?.fee,
			enabled: rawP2PField?.attributes?.coverFee?.attributes?.visible,
			default: rawP2PField?.attributes?.coverFee?.attributes?.checked,
			required: rawP2PField?.attributes?.coverFee?.attributes?.required,
			label: rawP2PField?.attributes?.coverFee?.attributes?.label,
			tooltip: rawP2PField?.attributes?.coverFee?.attributes?.tooltip,
		},
	}

	const masthead = {
		path:
			design.masthead.imageType === "none"
				? null
				: design.masthead.image.fullPath,
		align: design.masthead.logoAlign,
		height: design.masthead.height,
	}

	const accentColorObject = Color(
		design.fonts.accent.color || globalDefaultAccentColor
	)

	const accentColor = accentColorObject.isDark()
		? design.fonts.accent.color || globalDefaultAccentColor
		: globalDefaultAccentColor

	const fonts = {
		body: {
			face: design.fonts.general.face,
			isGoogle: design.fonts.general.googleFont,
		},
		headline: {
			face: design.fonts.headline.face,
			isGoogle: design.fonts.headline.googleFont,
		},
	}

	return {
		name,
		currency,
		formHash,
		masthead,
		accentColor,
		fonts,
		p2pField,
		settings,
	}
}

export { ApolloErrors } from "./general"
