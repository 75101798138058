import React from "react"
import Page from "@webconnex/rainbow/layout/page"
import Title from "@webconnex/rainbow/typography/title"

export const InvalidLink400 = () => {
	return (
		<Page>
			<Title
				style={{
					margin: "auto",
					marginTop: 100,
					width: "fit-content",
				}}
			>
				Error 400. Invalid peer to peer link.
			</Title>
		</Page>
	)
}

export const EmptyPayload400 = () => {
	return (
		<Page>
			<Title
				style={{
					margin: "auto",
					marginTop: 100,
					width: "fit-content",
				}}
			>
				Error 400. Unable to load form details.
			</Title>
		</Page>
	)
}
