import React, { Fragment, SyntheticEvent, useState } from "react"

import Text from "@webconnex/rainbow/typography/text"
import { InputCurrencyField } from "@webconnex/rainbow/forms/input-currency"

import Button from "../button/button"
import FormattedCurrency from "@webconnex/rainbow/components/formatted-currency"

import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"

import { useAskAmount } from "../../hooks/use-ask-amount/use-ask-amount"
import { globalDefaultAccentColor } from "../../utils"

interface Props {
	color?: string
	biggestGift: number
	currentRevenue: number
	goalRevenue: number
	currency: SupportedCurrencyCodes
	handleSuggestedChange: (amount: string) => void
	defaultValue?: string
	inputProps: any
}

const SuggestedDonation: React.FC<Props> = ({
	color = globalDefaultAccentColor,
	biggestGift,
	currentRevenue,
	goalRevenue,
	currency,
	handleSuggestedChange,
	defaultValue,
	inputProps,
}) => {
	const [askAmount, askDescription] = useAskAmount(
		biggestGift,
		goalRevenue,
		currentRevenue
	)

	const formattedAskAmount = (askAmount / 100).toFixed(2)

	const populateInput = (e: SyntheticEvent) => {
		setShowSuggestedAmount(true)
		handleSuggestedChange(formattedAskAmount)
	}
	const [showSuggestedAmount, setShowSuggestedAmount] = useState<boolean>(false)

	return (
		<Fragment>
			{!showSuggestedAmount && (
				<InputCurrencyField
					defaultValue={defaultValue}
					currencyCode={currency}
					required
					{...inputProps}
				/>
			)}
			{showSuggestedAmount && (
				<InputCurrencyField
					defaultValue={formattedAskAmount}
					currencyCode={currency}
					required
					{...inputProps}
				/>
			)}
			{askAmount > 0 && (
				<Text variant="light" className="dib" style={{ fontSize: "12px" }}>
					{askDescription}{" "}
					<Button
						emphasis="low"
						className="pa-0 underline"
						onClick={populateInput}
						style={{ fontSize: "inherit", color }}
						type="button"
					>
						by giving{" "}
						<FormattedCurrency
							value={askAmount}
							currencyCode={currency}
							precision={0}
							convertFromInteger={true}
							className="ml-1"
						/>
					</Button>{" "}
					or more!
				</Text>
			)}
		</Fragment>
	)
}

export default SuggestedDonation
