import React from "react"
import "./app.scss"
import { useRoutes } from "hookrouter"
import { PageRoutes, OtherRoutes } from "./routes"
import { PageProvider } from "./contexts"
import { PageError } from "./components/page-error/page-error"

const App: React.FC = () => {
	const pageRoutes = useRoutes(PageRoutes)
	const otherRoutes = useRoutes(OtherRoutes)

	let view
	if (otherRoutes) {
		// All other routes.
		view = otherRoutes
	} else if (pageRoutes) {
		// Routes that needs publishedPage loaded.
		view = <PageProvider>{pageRoutes}</PageProvider>
	} else {
		// Not found route.
		view = (
			<PageError
				message="Page Not Found"
				secondaryMessage="Looks like the page you were looking for does not exist."
			/>
		)
	}

	return view
}
export default App
