import React, { useState, useEffect } from "react"
import moment from "moment-timezone"
import cx from "classnames"

import Icon from "@webconnex/rainbow/components/icon"
import { formatNumber } from "@webconnex/rainbow/utils/number-format"

import { useInterval } from "../../hooks"
import styles from "./countdown.module.scss"

import { globalDefaultAccentColor } from "../../utils"

interface Props {
	deadline: string
	timeZone: string
	color?: string
}

const getDuration = (deadline: string, timeZone: string) => {
	let diffTime: number =
		moment.tz(deadline, timeZone).endOf("day").valueOf() -
		moment().tz(timeZone).valueOf()
	if (diffTime < 0) {
		// we've passed the deadline, but we don't show negatives
		diffTime = 0
	}
	return diffTime
}

const Countdown: React.FC<Props> = ({
	deadline,
	timeZone = moment.tz.guess(),
	color = globalDefaultAccentColor,
}) => {
	if (!deadline || deadline === "Invalid date") {
		return null
	}
	const diffTime = getDuration(deadline, timeZone)
	const [timeLeft, setTimeLeft] = useState(diffTime)
	const [durationLeft, setDurationLeft] = useState(moment.duration(diffTime))

	useInterval(() => {
		setTimeLeft(getDuration(deadline, timeZone))
	}, 1000)

	// the moment object isn't a primitive so deep changes
	// don't trigger a re-render
	useEffect(() => {
		setDurationLeft(moment.duration(timeLeft))
	}, [timeLeft])

	return (
		<h4 className={cx(styles.countdown, "pt-5 lg_pb-0")}>
			<Icon iconName="clock" style={{ marginRight: "2px" }} />
			<strong>
				{formatNumber(Math.floor(durationLeft.asDays()))}
			</strong> days <strong>{durationLeft.hours()}</strong> hrs{" "}
			<strong>{durationLeft.minutes()}</strong> mins{" "}
			<strong style={{ color }}>{durationLeft.seconds()}</strong> secs
		</h4>
	)
}

export default Countdown
