import React from "react"
import Page from "@webconnex/rainbow/layout/page"
import Title from "@webconnex/rainbow/typography/title"

export const ApolloError500 = () => {
	const devMsg =
		process.env.NODE_ENV !== "production" &&
		"It appears that the apollo server is not running. Please start the apollo server"

	return (
		<Page>
			<Title
				style={{
					margin: "auto",
					marginTop: 100,
					width: "fit-content",
				}}
			>
				Error 500. Unable to connect to apollo. {devMsg}
			</Title>
		</Page>
	)
}

export const DataLoadingError = () => (
	<Page>
		<Title
			style={{
				margin: "auto",
				marginTop: 100,
				width: "fit-content",
			}}
		>
			Error 500. Unable to load data.
		</Title>
	</Page>
)
