import { PaginatedDataType } from "../types/common"
import { QueryResult } from "@apollo/react-common"

export type FetchMoreQuery = (options: {
	variables: {
		offset?: number
		page?: number
	}
	updateQuery: (prev: any, { fetchMoreResult }: any) => any
}) => any

/**
 * A helper to fetch more of any apollo query,
 * while preserving already fetched records.
 *
 * @param supporterQueryRes the supporter useQuery response from graphql.
 * @param nextPage the next page of supporters to query for.
 * @param setSupportersData state setter for supportersData.
 */
export const fetchMore = function(
	fetchMoreQuery: FetchMoreQuery,
	queryName: string,
	result: QueryResult<any, any>
): any {
	const paginatedResults: PaginatedDataType = result.data?.response[queryName]
	const nextPage = paginatedResults.nextPage

	if (!nextPage) {
		return
	}

	// Fetch the next page.
	fetchMoreQuery({
		variables: {
			page: nextPage,
		},
		updateQuery: (
			prev,
			{ fetchMoreResult }: { fetchMoreResult: { response: any } }
		) => {
			const previousRecords = prev?.response[queryName].records || []
			const newRecords = fetchMoreResult?.response[queryName]?.records || []
			const joinedRecords = [...previousRecords, ...newRecords]
			const newResult = { ...fetchMoreResult }
			newResult.response[queryName].records = joinedRecords

			return newResult
		},
	})
}
