import { useLazyQuery, useMutation } from "@apollo/react-hooks"
import { WbcxURL } from "@webconnex/rainbow/utils/url"
import { loader } from "graphql.macro"
import { useQueryParams } from "hookrouter"
import React, { useEffect, useState } from "react"
import useAuth from "../../hooks/use-auth/use-auth"
import { pagePathToEnv } from "../../utils/url"

const exchangeTokenGQL = loader(
	"../../graphql/mutations/exchange-token.graphql"
)
const supportersByContact = loader(
	"../../graphql/queries/contact-supporters.graphql"
)

// This immediately-evaluated anonymous function exists mostly to encapsulate
// variables only it needs.
const visitorIdStorage = (() => {
	const visitorIdKey = "visitorId",
		visitorIdExpirationKey = "visitorIdExpiration",
		cacheTTLDays = 30,
		removeVisitorId = (): void => {
			localStorage.removeItem(visitorIdExpirationKey)
			localStorage.removeItem(visitorIdKey)
		},
		expirationDate = (): string => {
			const date = new Date()
			const dayOfMonth = date.getDate() + cacheTTLDays
			date.setDate(dayOfMonth)
			return date.toISOString()
		}

	return {
		get: (): string => {
			// Is the the expiration date present?
			const visitorIdExpirationStr = localStorage.getItem(
				visitorIdExpirationKey
			)
			if (!visitorIdExpirationStr) {
				removeVisitorId()
				return ""
			}

			// Is the the expiration date valid?
			const visitorIdExpiration = new Date(visitorIdExpirationStr)
			// Returns undefined if date is invalid
			if (!visitorIdExpiration.valueOf()) {
				removeVisitorId()
				return ""
			}

			// Is the the expiration date in the past?
			const now = new Date()
			if (visitorIdExpiration < now) {
				// visitorId is expired
				removeVisitorId()
				return ""
			}

			return localStorage.getItem(visitorIdKey) || ""
		},
		set: (visitorId: string) => {
			localStorage.setItem(visitorIdExpirationKey, expirationDate())
			localStorage.setItem(visitorIdKey, visitorId)
		},
	}
})()

const Exchange: React.FC = () => {
	const [{ redirectUrl }] = useQueryParams()

	const [contactId, setContactId] = useState(0)
	const { exchangeToken, setSession, updateSession } =
		useAuth("p2p_wbcx_session")

	const [exchangeTokenMutation, { loading, data }] = useMutation(
		exchangeTokenGQL,
		{
			fetchPolicy: "no-cache",
		}
	)

	const [
		supportersByContactQuery,
		{ loading: supportersLoading, data: supportersData },
	] = useLazyQuery(supportersByContact, {
		fetchPolicy: "no-cache",
	})

	useEffect(() => {
		const parsedURL = new WbcxURL(window.location.href, true)

		if (!parsedURL.query.token) {
			// No token to exchange. Redirect them to the redirect url.
			window.location.href = redirectUrl
			return
		}

		// we need to get the exchange query into the session to be used
		// in the apollo header
		setSession(parsedURL.query.token || "", 60)

		exchangeToken(exchangeTokenMutation).then(response => {
			if (!response) {
				return
			}
			setContactId(response.token.userId)
			supportersByContactQuery({
				variables: {
					page: 1,
					limit: 250,
				},
			})
		})
	}, [])

	useEffect(() => {
		if (
			(data && !data?.response?.success) ||
			(supportersData && !supportersData?.response?.success)
		) {
			// Token exchange not success. Forward to redirect url without saving token.
			window.location.href = redirectUrl
			return
		}

		if (!supportersLoading && supportersData?.response?.success) {
			const singleSupporter =
				supportersData.response?.supporters?.records.length === 1

			const supporterId = singleSupporter
				? supportersData.response?.supporters?.records[0]?.supporterId
				: null

			const editURL = singleSupporter
				? pagePathToEnv(
						supportersData.response?.supporters?.records[0]?.masterPage?.path,
						supporterId
				  )
				: window.location.href.replace("exchange", "page-list")

			updateSession(undefined, undefined, {
				editURL,
				supporterId,
				contactId,
			})
			window.location.replace(editURL)
		}
	}, [supportersLoading, supportersData, loading, data])

	return null
}

export default Exchange
