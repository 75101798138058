import React from "react"
import cx from "classnames"
import Button from "../button/button"
import ProgressBar from "../progress-bar/progress-bar"
import Title from "@webconnex/rainbow/typography/title"
import Pane from "@webconnex/rainbow/layout/pane"
import Grid, { GridColumn } from "@webconnex/rainbow/layout/grid"
import Link from "@webconnex/rainbow/typography/link"
import Box, { BoxBody } from "@webconnex/rainbow/layout/box"
import Icon from "@webconnex/rainbow/components/icon"
import { SupporterType } from "../../types/supporter"
import styles from "./supporters.module.scss"
import { globalDefaultAccentColor } from "../../utils"
import { toPage } from "../../utils/navigation"
import { usePaginatedSupporters } from "../../hooks"
import { PaginatedDataType } from "../../types/common"
import { getFullName } from "../../utils/supporters"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"
import { S3Image } from "@webconnex/rainbow/components/s3-image"

const s3ImageServiceURL =
	process.env.S3_IMAGE_SERVICE_URL ||
	process.env.REACT_APP_S3_IMAGE_SERVICE_URL ||
	"localhost"

interface Props {
	pageSlug: string
	color?: string
	findAction(): void
}

const Supporters: React.FC<Props> = ({
	pageSlug,
	color = globalDefaultAccentColor,
	findAction,
}) => {
	const {
		result: { data, loading },
		fetchMore,
	} = usePaginatedSupporters()
	const paginatedSupporters = data?.response?.supporters as PaginatedDataType
	const totalSupporters = paginatedSupporters?.totalRecords || 0
	const totalPages = paginatedSupporters?.totalPages || 0
	const currentPage = paginatedSupporters?.currentPage || 1
	const supporters = paginatedSupporters?.records || ([] as SupporterType[])

	// Check if there are more pages that can be shown.
	const showMoreButton: boolean = currentPage < totalPages

	const supporterCards = supporters.map(
		(supporter: SupporterType, i: number) => {
			const profileImage = supporter?.profileImage
			const fullName = getFullName(supporter)

			return (
				<GridColumn xs={6} md={4} lg={6} xl={4} key={`supporter-${i}`}>
					<Link
						style={{ cursor: "pointer" }}
						onClick={() => {
							toPage(`/${pageSlug}/fundraiser/${supporter.supporterId}`)
						}}
					>
						<Box className={cx(styles.card, "h-100")} noStretch>
							<figure className="bg-gray-10">
								{profileImage && (
									<S3Image
										s3ImageServiceURL={s3ImageServiceURL}
										image={profileImage}
									/>
								)}
							</figure>
							<BoxBody className="pa-4 ma-0">
								<Pane spacing={3}>
									<Title size={6} className="fwb">
										{fullName || ""}
									</Title>
									<ProgressBar
										currentRevenue={supporter?.orderSum?.amount}
										goalRevenue={supporter?.goalAmount?.amount}
										currency={
											(supporter?.goalAmount
												?.currency as SupportedCurrencyCodes) ||
											(supporter?.pagePublished?.pageSnapshot
												?.currency as SupportedCurrencyCodes) ||
											"USD"
										}
										location="card"
										color={color}
									/>
								</Pane>
							</BoxBody>
						</Box>
					</Link>
				</GridColumn>
			)
		}
	)

	return (
		<Pane className="mb-7">
			<Pane xs="row" spacing={4} className="items-center justify-between">
				<Title size={2}>
					{loading ? "Loading" : totalSupporters} Fundraisers
				</Title>
				<Button emphasis="low" className="pa-0 fwb f-4" onClick={findAction}>
					<Icon iconName="zoom" placement="left" />
					Search
				</Button>
			</Pane>
			<Grid className="mv-5">{supporterCards}</Grid>
			{showMoreButton && (
				<Button
					emphasis="low"
					variant="primary"
					onClick={fetchMore}
					style={{ color }}
					className="pa-0 fwb mb-5 mt--4"
				>
					Show More
				</Button>
			)}
		</Pane>
	)
}

export default Supporters
