import moment from "moment-timezone"
import React from "react"
import { InputSelectField } from "@webconnex/rainbow/forms/input-select"

const usZones = [
	{
		name: "America/New_York",
		abbr: "Eastern",
	},
	{
		name: "America/Chicago",
		abbr: "Central",
	},
	{
		name: "America/Denver",
		abbr: "Mountain",
	},
	{
		name: "America/Los_Angeles",
		abbr: "Pacific",
	},
]

const usZoneOptions = Object.keys(usZones).map((key: any) => (
	<option key={usZones[key].name} value={usZones[key].name}>
		{usZones[key].abbr}
	</option>
))
usZoneOptions.push(<option key="divider">-------------</option>)

const allZones = moment.tz
	.names()
	.filter(
		(name: string) =>
			![
				"America/New_York",
				"America/Chicago",
				"America/Denver",
				"America/Los_Angeles",
			].includes(name)
	)
	.map((name: string) => (
		<option key={name} value={name}>
			{name}
		</option>
	))

interface Props {
	inputProps: any
	disabled: boolean
}

const TimeZoneSelect: React.FC<Props> = ({ inputProps, disabled }) => (
	<InputSelectField {...inputProps} label="Time Zone" disabled={disabled}>
		{usZoneOptions.concat(allZones)}
	</InputSelectField>
)

export default TimeZoneSelect
