import React from "react"
import {
	Master,
	Supporter,
	Login,
	SignUp,
	Confirm,
	Exchange,
	PageList,
} from "./pages"

export const PageRoutes = {
	"/:slug": () => <Master />,
	"/:slug/": () => <Master />,
	"/:slug/sign-up": () => <SignUp />,
	"/:slug/sign-up/": () => <SignUp />,
	"/:slug/login": () => <Login />,
	"/:slug/login/": () => <Login />,
	"/:slug/fundraiser/:supporterId": () => <Supporter />,
}
export const OtherRoutes = {
	"/confirm": () => <Confirm />,
	"/exchange": () => <Exchange />,
	"/page-list": () => <PageList />,
}
