export type MaintenanceData = {
	status: number
	statusMessage: string
	statusDescription: {
		code: number
		reason: string
	}
	statusStart: string
	statusEnd: string
}

export class MaintenanceError extends Error {
	private _data: MaintenanceData

	get data() {
		return { ...this._data }
	}

	constructor(data: MaintenanceData) {
		super("error")
		this._data = data
	}
}
