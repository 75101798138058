import React, { useState } from "react"
import cx from "classnames"
import List from "@webconnex/rainbow/components/list"
import Button from "@webconnex/rainbow/components/button"
import Icon from "@webconnex/rainbow/components/icon"
import Hamburger from "@webconnex/rainbow/icons/hamburger"
import Remove from "@webconnex/rainbow/icons/remove"
import Pane from "@webconnex/rainbow/layout/pane"
import styles from "./super-nav.module.scss"
import useAuth from "../../hooks/use-auth/use-auth"
import { toPage } from "../../utils/navigation"

interface Props {
	canCreatePage: boolean
	pageSlug: string
	contactEmail: string
	handleEdit?: (val: boolean) => void
}

const SuperNav: React.FC<Props> = ({
	canCreatePage,
	pageSlug,
	contactEmail,
	handleEdit,
}) => {
	const [close, setClose] = useState(false)

	const showEdit = () => {
		if (handleEdit) {
			handleEdit(true)
		}
	}
	const {
		loggedIn,
		logOut,
		session: { data: { editURL } = { editURL: "" } },
	} = useAuth("p2p_wbcx_session")

	return (
		<Pane xs="row" className={styles.superNav}>
			<Button
				emphasis="low"
				className={cx(
					{ fixed: close },
					"db md_dn absolute left-4 top-4 z-9999 pa-0"
				)}
				onClick={() => {
					setClose(!close)
				}}
			>
				<Icon
					size="xlarge"
					icon={!close ? Hamburger : Remove}
					className={!close ? "gray-50" : "white"}
				/>
			</Button>
			<List
				className={cx(
					{ [styles.mobileNavOpen]: close, [styles.mobileNav]: !close },
					"md_justify-end md_items-center w-100 bg-gray-100"
				)}
				md="row"
				spacing={5}
				as="nav"
			>
				{!loggedIn && pageSlug && (
					<a
						onClick={() => toPage(`/${pageSlug}/login`)}
						className="fwb white md_mh-2"
					>
						Login
					</a>
				)}
				{!loggedIn && contactEmail && (
					<a href={`mailto:${contactEmail}`} className="fwb white md_mh-1">
						Contact
					</a>
				)}
				{/* Logged in and on my supporter page */}
				{loggedIn && handleEdit && (
					<Button
						emphasis="low"
						onClick={showEdit}
						className="fwb white md_mh-1 pa-0"
					>
						Edit My Page
					</Button>
				)}
				{/* Logged in but not on my supporter page */}
				{loggedIn && !handleEdit && editURL && (
					<Button
						as="a"
						className="fwb white md_mh-1 pa-0"
						emphasis="low"
						href={editURL}
					>
						Go To My Page
					</Button>
				)}
				{loggedIn && (
					<Button
						emphasis="low"
						onClick={logOut}
						className="fwb white md_mh-1 pa-0"
					>
						Log Out
					</Button>
				)}
				{canCreatePage && pageSlug && (
					<Button
						as="a"
						onClick={() => toPage(`/${pageSlug}/sign-up`)}
						size="small"
						className="fwb bg-white md_mh-1"
						style={{ height: "32px", lineHeight: "1em" }}
					>
						Fundraise For This Cause
					</Button>
				)}
			</List>
		</Pane>
	)
}

export default SuperNav
