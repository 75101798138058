import currencyInfo from "./currencies"
import { convertToInteger } from "@webconnex/rainbow/utils/number-format"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"

export const convertCurrencyToInteger = (
	num: number | string,
	currency: SupportedCurrencyCodes
) => {
	const currencySettings = currencyInfo[currency] || currencyInfo.USD
	return convertToInteger(num, currencySettings.decimalScale)
}
