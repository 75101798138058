import { S3ImageType } from "@webconnex/rainbow/types/image"
import React from "react"
import { IntroSupporter } from "../../../components"

interface Props {
	pageSlug: string
	intro: string
	pageName: string
	firstName: string
	lastName: string
	profileImage: S3ImageType
}

const InfoIntro: React.FC<Props> = ({
	pageSlug,
	intro,
	pageName,
	firstName,
	lastName,
	profileImage,
}) => {
	return (
		<IntroSupporter
			firstName={firstName}
			lastName={lastName}
			pageName={pageName}
			pageSlug={pageSlug}
			description={intro}
			profileImage={profileImage}
		/>
	)
}

export default InfoIntro
