export const useAskAmount = (
	biggestGift: number,
	goalRevenue: number,
	currentRevenue: number
): [number, string] => {
	if (biggestGift === 0) {
		return [0, ""]
	}
	// adding one dollar or 100 cents
	let askAmount = biggestGift + 100

	let askDescription = "Become the biggest donor"

	// If we are $300 or closer to the goal,
	// don't ask to top the biggest donation
	// ask to reach the goal.
	const goalRevenueThreshold = 30000
	if (
		goalRevenue > currentRevenue &&
		goalRevenue - currentRevenue < goalRevenueThreshold
	) {
		askAmount = goalRevenue - currentRevenue
		askDescription = "Meet the goal"
	}

	return [askAmount, askDescription]
}
