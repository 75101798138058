import { useQuery } from "@apollo/react-hooks"
import { loader } from "graphql.macro"
import { parseP2PUrl } from "../../utils/url"
import { fetchMore } from "../../utils/apollo"

const getSupportersGQL = loader("../../graphql/queries/supporters.graphql")
const queryName = "supporters"

type Props = {
	limit?: number
	name?: string
	page?: number
}

const usePaginatedSupporters = (props?: Props) => {
	const { pathParams } = parseP2PUrl()

	const result = useQuery(getSupportersGQL, {
		variables: { ...pathParams, ...props },
		fetchPolicy: "cache-and-network",
	})

	// Setup fetchMore function.
	const fetchMoreSupporters = () =>
		fetchMore(result.fetchMore, queryName, result)

	return { result, fetchMore: fetchMoreSupporters }
}

export default usePaginatedSupporters
