import { I18nLanguageStrings } from "@webconnex/rainbow/hooks/use-i18n/language-types"

export const i18nStrings: I18nLanguageStrings = {
	en: {
		code: "en",
		name: "English",
		strings: {
			title_receipt: "Success!",
			label_total: "Total",
			label_name: "Name on Card",
			label_payment_method: "Payment Method",
			label_credit_card: "Credit Card",
			label_address: "Address",
			label_email: "Email",
			label_date: "Date",
			p_receipt_thanks: (name: string) =>
				`Thank you for your payment towards ${name}'s registration! You will receive a confirmation email and ${name} will receive a notification of your payment.
			Below are your payment details.`,
		},
	},
}
