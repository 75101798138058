import React from "react"

type QueryResult = {
	loading?: boolean
	data?: any
	error?: any
}

type Func = () => any
type Settings = {
	customLoading?: JSX.Element | Func
	customError?: JSX.Element | Func
	customData?: JSX.Element | Func
	customNotSuccessful?: JSX.Element | Func
}

export const parseResponse = (
	{ loading, data, error }: QueryResult,
	settings?: Settings
): JSX.Element | undefined => {
	const { customLoading, customData, customError, customNotSuccessful } =
		settings || (({} as any) as Settings)
	if (loading) {
		if (customLoading) {
			if (typeof customLoading === "function") {
				customLoading()
				return
			}
			return customLoading
		}

		return <div>loading</div>
	}
	if (error) {
		if (customError) {
			if (typeof customError === "function") {
				customError()
				return
			}
			return customError
		}
		return <div>500 error</div>
	}
	if (!data) {
		if (customData) {
			if (typeof customData === "function") {
				customData()
				return
			}
			return customData
		}
		return <div>500 error</div>
	}

	if (data && data.response && !data.response.success) {
		if (customNotSuccessful) {
			if (typeof customNotSuccessful === "function") {
				customNotSuccessful()
				return
			}
			return customNotSuccessful
		}
		return <div>404 Not found</div>
	}
}
