import React, { Fragment, useState } from "react"
import { Button, CreateEditPage } from "../index"
import { editValidations as validations, submitEditPageForm } from "./utils"
import { globalDefaultAccentColor } from "../../utils"
import moment from "moment-timezone"
import { loader } from "graphql.macro"

import Text from "@webconnex/rainbow/typography/text"
import Title from "@webconnex/rainbow/typography/title"
import { InputTextField } from "@webconnex/rainbow/forms/input-text"
import Grid, { GridColumn } from "@webconnex/rainbow/layout/grid"
import useForm from "@webconnex/rainbow/hooks/use-form"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"
import { S3ImageType } from "@webconnex/rainbow/types/image/s3-images"
import { useS3Upload } from "@webconnex/rainbow/hooks/use-s3-upload"

const getSignedUrlGQL = loader("../../graphql/queries/get-signed-url.graphql")

interface Props {
	supporterId: number
	firstName: string
	lastName: string
	goalAmount: number
	goalCurrency: SupportedCurrencyCodes
	fundraisingDeadline: string
	timeZone: string
	includeMasterPhotos: boolean
	videoURL: string
	formName: string
	color?: string
	introText: string
	customThankYou: string
	editSupporterMutation: any
	profileImage: S3ImageType
	galleryImages: S3ImageType[]
	allowGoalChange: boolean
	allowDeadlineChange: boolean
	closeCallBack(): void
}

const EditPage: React.FC<Props> = ({
	supporterId,
	firstName,
	lastName,
	goalAmount,
	goalCurrency,
	fundraisingDeadline,
	timeZone,
	introText,
	customThankYou,
	includeMasterPhotos,
	videoURL,
	formName,
	color = globalDefaultAccentColor,
	editSupporterMutation,
	profileImage,
	galleryImages,
	allowGoalChange,
	allowDeadlineChange,
	closeCallBack,
}) => {
	const [pageError, setPageError] = useState<string>("")

	const profileImageHandler = {
		...useS3Upload(getSignedUrlGQL, "supporter", {
			...profileImage,
			type: "profile",
		}),
	}

	// if there are no gallery images, create an empty array
	const galleryImagesHandler = !galleryImages?.length
		? []
		: galleryImages.map((image: S3ImageType) => ({
				...useS3Upload(getSignedUrlGQL, "supporter", {
					...image,
					type: "gallery",
				}),
		  }))

	while (galleryImagesHandler.length < 5) {
		// it is possible they may have uploaded 1 or
		// 2 photos, but we want there to be three slots
		galleryImagesHandler.push({
			...useS3Upload(getSignedUrlGQL, "supporter", {
				type: "gallery",
			}),
		})
	}

	const submit = async (submitValues: Record<string, any>) =>
		submitEditPageForm(
			supporterId,
			submitValues,
			profileImageHandler,
			galleryImagesHandler,
			galleryImages,
			goalCurrency,
			editSupporterMutation,
			setPageError,
			closeCallBack
		)

	const initialValues = {
		firstName,
		lastName,
		goalAmount: goalAmount.toString(),
		introText,
		fundraisingDeadline:
			fundraisingDeadline !== ""
				? moment(fundraisingDeadline).utc().format("YYYY-MM-DD")
				: "",
		customThankYou,
		timeZone,
		includeMasterPhotos: includeMasterPhotos ? "include" : "",
		videoURL,
	}

	const { inputProps, isSubmitting, setValue, handleSubmit } = useForm({
		initialValues,
		validations,
		onSubmit: submit,
	})

	const changeFundraisingDeadline = (date: string): void =>
		setValue("fundraisingDeadline", date)

	return (
		<Fragment>
			<Title size={2} className="fwb mb-6" style={{ color }}>
				Edit your personal fundraising page for {formName}
			</Title>
			{pageError && (
				<Text variant="danger" className="mb-4 db">
					{pageError}
				</Text>
			)}
			<form noValidate onSubmit={handleSubmit}>
				<Grid>
					<GridColumn md={6}>
						<InputTextField
							required
							label="First Name"
							{...inputProps.firstName}
						/>
					</GridColumn>
					<GridColumn md={6}>
						<InputTextField
							required
							label="Last Name"
							{...inputProps.lastName}
						/>
					</GridColumn>
				</Grid>
				<CreateEditPage
					allowDeadlineChange={allowDeadlineChange}
					allowGoalChange={allowGoalChange}
					inputProps={inputProps}
					currency={goalCurrency}
					changeFundraisingDeadline={changeFundraisingDeadline}
					profileImage={profileImageHandler}
					galleryImages={galleryImagesHandler}
				/>
				{pageError && (
					<Text variant="danger" className="mb-4 db">
						{pageError}
					</Text>
				)}
				<Button
					style={{
						background: color,
						border: "none",
					}}
					className="mt-8 fwb white w-100 md_w-auto mb-4"
					size="default"
					type="submit"
					disabled={isSubmitting}
					loading={isSubmitting}
				>
					Update Your Page
				</Button>
			</form>
		</Fragment>
	)
}

export default EditPage
