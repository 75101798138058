import React from "react"
import cx from "classnames"
import Pane from "@webconnex/rainbow/layout/pane"

interface Props {
	src?: string
	alt?: string
	height?: number
	align?: string
}

const Masthead: React.FC<Props> = ({ src, align, height, alt = "Logo" }) => {
	if (!src) {
		return null
	}
	if (!height) {
		height = 145
	}
	return (
		<Pane
			style={{ height: `${height}px` }}
			className={cx(
				`items-${
					align === "center" ? "center" : "start"
				} justify-center pa-4 md_pa-5`
			)}
		>
			<img src={src} alt={alt} className="max-w-80 max-h-100 pa-2 md_pa-0" />
		</Pane>
	)
}

export default Masthead
