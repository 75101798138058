import { useState, useLayoutEffect } from 'react';

function useWindowSize() {
  const isClient = typeof window === 'object'

  const breakpoints = {
    xs: 320,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1200,
  }

  type breakPoints = "xs"|"sm"|"md"|"lg"|"xl"

  function getSize() {
    const width = window.innerWidth
    return {
      width: isClient ? width : undefined,
      height: isClient ? window.innerHeight : undefined,
      isWiderThan: (breakpoint: breakPoints) => {
        return width > breakpoints[breakpoint]
      },
      isNarrowerThan: (breakpoint: breakPoints) => {
        return width < breakpoints[breakpoint]
      },
    }
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useLayoutEffect(() => {

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export default useWindowSize
