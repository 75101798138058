import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks"
import I18nProvider from "@webconnex/rainbow/hooks/use-i18n/use-i18n"
import { IconLibraryProvider } from "@webconnex/rainbow/hooks/use-icon-library"
import ChevronLeft from "@webconnex/rainbow/icons/chevron-left"
import ChevronRight from "@webconnex/rainbow/icons/chevron-right"
import Clock from "@webconnex/rainbow/icons/clock"
import Mail from "@webconnex/rainbow/icons/mail"
import SolidCockade from "@webconnex/rainbow/icons/solid-cockade"
import SolidTrophy from "@webconnex/rainbow/icons/solid-trophy"
import Zoom from "@webconnex/rainbow/icons/zoom"
import ApolloClient from "apollo-boost"
import { setBasepath } from "hookrouter"
import React from "react"
import { ApolloProvider } from "react-apollo"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import ReactDOM from "react-dom"
import App from "./app"
import "./app.scss"
import useAuth from "./hooks/use-auth/use-auth"
import { i18nStrings } from "./i18n.strings"

// Set basepath for micro-frontend.
setBasepath(`${process.env.REACT_APP_P2P_BASEPATH}`)

const ReactApp = () => {
	const client = new ApolloClient({
		uri: process.env.REACT_APP_APOLLO_URL,
		request: (operation: any) => {
			const {
				session: { token },
			} = useAuth("p2p_wbcx_session")
			operation.setContext({
				headers: {
					authorization: token ? `Bearer ${token}` : "",
				},
			})
		},
	})

	return (
		<ApolloProvider client={client}>
			<ApolloHooksProvider client={client}>
				<I18nProvider strings={i18nStrings}>
					<IconLibraryProvider
						icons={[
							Clock,
							Zoom,
							ChevronRight,
							ChevronLeft,
							SolidTrophy,
							SolidCockade,
							Mail,
						]}
					>
						<App />
					</IconLibraryProvider>
				</I18nProvider>
			</ApolloHooksProvider>
		</ApolloProvider>
	)
}
ReactDOM.render(<ReactApp />, document.getElementById("root"))
