import React, { Fragment, useState } from "react"
import { loader } from "graphql.macro"
import { useQuery, useMutation } from "@apollo/react-hooks"
import Link from "@webconnex/rainbow/typography/link"
import Text from "@webconnex/rainbow/typography/text"
import Title from "@webconnex/rainbow/typography/title"
import { InputTextField } from "@webconnex/rainbow/forms/input-text"
import Page, { PageBody } from "@webconnex/rainbow/layout/page"
import Grid, { GridColumn } from "@webconnex/rainbow/layout/grid"
import {
	SuperNav,
	Masthead,
	Button,
	CreateEditPage,
	CustomHead,
} from "../../components"
import useForm from "@webconnex/rainbow/hooks/use-form"
import { createValidations as validations } from "../../components/create-edit-page/utils"
import { submitSignupForm, defaultIntro } from "./util"
import { parsePublishedPageResponse, ApolloErrors } from "../../utils"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"
import { sanitizer } from "@webconnex/rainbow/utils/security"
import { parseResponse } from "../../to-rainbow/parse-apollo-response"
import { toPage } from "../../utils/navigation"
import { usePage } from "../../contexts"
import { useS3Upload } from "@webconnex/rainbow/hooks/use-s3-upload"

const getSignedUrlGQL = loader("../../graphql/queries/get-signed-url.graphql")
const createSupporter = loader(
	"../../graphql/mutations/create-supporter.graphql"
)
const getPublishedPage = loader("../../graphql/queries/published-page.graphql")

const SignUp: React.FC = () => {
	const { pathParams, pageSlug, refetch } = usePage()

	const [createSupporterMutation] = useMutation(createSupporter)

	const [pageError, setPageError] = useState<string>("")
	const [allowGoalChange, setAllowGoalChange] = useState(true)

	const submit = async (submitValues: Record<string, any>) =>
		submitSignupForm(
			submitValues,
			profileImage,
			galleryImages,
			pathParams.domain,
			pathParams.subdomain,
			pathParams.p2pSlug,
			data.response.publishedPage.pageSnapshot.currency,
			createSupporterMutation,
			setPageError,
			refetch
		)

	const initialValues = {
		firstName: "",
		lastName: "",
		email: "",
		confirmEmail: "",
		goalAmount: "",
		introText: defaultIntro,
		customThankYou:
			"Thank you so much for your support! You've helped me get one step closer to my goal!",
		fundraisingDeadline: "",
		timeZone: "",
		includeMasterPhotos: "include",
		videoURL: "",
	}

	const { inputProps, isSubmitting, setValue, handleSubmit } = useForm({
		initialValues,
		validations,
		onSubmit: submit,
	})

	const profileImage = {
		...useS3Upload(getSignedUrlGQL, "supporter", { type: "profile" }),
	}

	const galleryImages = [
		{ ...useS3Upload(getSignedUrlGQL, "supporter", { type: "gallery" }) },
		{ ...useS3Upload(getSignedUrlGQL, "supporter", { type: "gallery" }) },
		{ ...useS3Upload(getSignedUrlGQL, "supporter", { type: "gallery" }) },
		{ ...useS3Upload(getSignedUrlGQL, "supporter", { type: "gallery" }) },
		{ ...useS3Upload(getSignedUrlGQL, "supporter", { type: "gallery" }) },
	]

	const gotPublishedPage = () => {
		const { p2pField } = parsePublishedPageResponse(
			data.response.publishedPage.pageSnapshot
		)
		setAllowGoalChange(p2pField.allowGoalChange)
		if (!p2pField.allowGoalChange && p2pField.supporterGoal === 0) {
			setAllowGoalChange(true)
		}
		setValue("goalAmount", p2pField.supporterGoal)
		setValue("fundraisingDeadline", p2pField.deadline)
		setValue("timeZone", p2pField.timeZone)
	}

	const { loading, data, error } = useQuery(getPublishedPage, {
		variables: pathParams,
		fetchPolicy: "cache-and-network",
		onCompleted: gotPublishedPage,
	})

	const returnEarly = parseResponse(
		{
			loading,
			data,
			error,
		},
		ApolloErrors
	)

	if (returnEarly) return returnEarly

	const { name, currency, masthead, accentColor, fonts, p2pField, settings } =
		parsePublishedPageResponse(data.response.publishedPage.pageSnapshot)

	const changeFundraisingDeadline = (date: string): void =>
		setValue("fundraisingDeadline", date)

	return (
		<Fragment>
			<CustomHead
				fonts={fonts}
				settings={settings}
				title={"Sign Up | " + name}
			/>
			<SuperNav
				canCreatePage={p2pField.allowSupporters}
				contactEmail={p2pField.contactEmail}
				pageSlug={pageSlug}
			/>
			<Masthead
				src={masthead.path}
				align={masthead.align}
				height={masthead.height}
			/>
			<Page maxWidth="small">
				<PageBody className="w-100">
					<Title className="mb-8 fwb" style={{ color: accentColor }}>
						Create a personal fundraising page for {name}
					</Title>
					<Title size={3} className="fwb">
						Sign Up
					</Title>
					<Text size={3} variant="medium">
						Already signed up?{" "}
						<Link
							variant="primary"
							style={{ color: accentColor }}
							size={3}
							onClick={() => {
								toPage(`/${pageSlug}/login`)
							}}
						>
							Login
						</Link>{" "}
						instead.
					</Text>
					{p2pField.signUpInstructions && (
						<Text
							dangerouslySetInnerHTML={{
								__html: sanitizer(p2pField.signUpInstructions),
							}}
							as="div"
							className="mb-5"
							style={{ lineHeight: "1.6em" }}
						/>
					)}
					<form className="mt-6" noValidate onSubmit={handleSubmit}>
						{pageError && (
							<Text variant="danger" className="mb-4 db">
								{pageError}
							</Text>
						)}
						<Grid>
							<GridColumn md={6}>
								<InputTextField
									required
									label="First Name"
									{...inputProps.firstName}
								/>
							</GridColumn>
							<GridColumn md={6}>
								<InputTextField
									required
									label="Last Name"
									{...inputProps.lastName}
								/>
							</GridColumn>
							<GridColumn>
								<InputTextField required label="Email" {...inputProps.email} />
							</GridColumn>
							<GridColumn>
								<InputTextField
									required
									label="Confirm Email"
									{...inputProps.confirmEmail}
								/>
							</GridColumn>
						</Grid>
						<Title size={3} className="mt-8 fwb">
							Create Your Page
						</Title>
						<CreateEditPage
							inputProps={inputProps}
							currency={currency as SupportedCurrencyCodes}
							changeFundraisingDeadline={changeFundraisingDeadline}
							allowGoalChange={allowGoalChange}
							allowDeadlineChange={p2pField.allowDeadlineChange}
							profileImage={profileImage}
							galleryImages={galleryImages}
						/>
						{pageError && (
							<Text variant="danger" className="mb-4 db">
								{pageError}
							</Text>
						)}
						<Button
							style={{
								background: accentColor,
								border: "none",
							}}
							className="mt-8 white fwb w-100 md_w-auto"
							type="submit"
							size="default"
							onClick={handleSubmit}
							disabled={isSubmitting}
							loading={isSubmitting}
						>
							Create Your Page
						</Button>
					</form>
				</PageBody>
			</Page>
		</Fragment>
	)
}

export default SignUp
