import React, { Fragment, useEffect } from "react"
import { sanitizer } from "@webconnex/rainbow/utils/security"
import { useTitle } from "hookrouter"
import { useCheckpoints } from "../../hooks/use-checkpoints/use-checkpoints"
import { Fonts, insertGoogleFonts, insertMeta, insertTracking } from "./utils"
interface Props {
	fonts: Fonts
	settings: any
	formHash?: string
	title?: string
	trackCheckpoints?: boolean
	supporterId?: number
}

const CustomFonts: React.FC<Props> = ({
	fonts,
	settings,
	formHash,
	title = "Fundraising",
	trackCheckpoints = false,
	supporterId,
}) => {
	useTitle(title)
	useEffect(() => {
		insertMeta(settings)
		insertTracking(settings)
		insertGoogleFonts(fonts)
	}, [])

	if (trackCheckpoints && formHash) {
		useCheckpoints(formHash, supporterId)
	}
	const styleString = `body div#root { font-family: ${fonts.body.face}; } body div#root label, body div#root button, body div#root a[class^="button"] { font-family: ${fonts.body.face}; } h1, h2, h3, h4, h5, h6 { font-family: ${fonts.headline.face}; }`

	return (
		<Fragment>
			<style
				dangerouslySetInnerHTML={{
					__html: sanitizer(styleString),
				}}
			/>
		</Fragment>
	)
}

export default CustomFonts
