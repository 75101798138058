import React from "react"
import cx from "classnames"
import Pane from "@webconnex/rainbow/layout/pane"
import FormatedCurrency from "@webconnex/rainbow/components/formatted-currency"
import { formatPercent } from "@webconnex/rainbow/utils/number-format"

import styles from "./progress-bar.module.scss"

import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"

import { globalDefaultAccentColor } from "../../utils"

interface Props {
	currentRevenue: number
	goalRevenue: number
	currency: SupportedCurrencyCodes
	location: "form" | "card"
	color?: string
}

const ProgressBar: React.FC<Props> = ({
	color = globalDefaultAccentColor,
	currentRevenue,
	currency,
	goalRevenue,
	location,
}) => {
	let percent = goalRevenue ? currentRevenue / goalRevenue : 0

	// we won't show over fundeded forms as having
	// greater than 100 percent
	if (percent > 1) {
		percent = 1
	}

	return (
		<Pane
			className={cx(
				styles.progressBar,
				styles["progressBar-" + location],
				"items-end justify-between"
			)}
			xs="row-wrap"
		>
			<h2 className="fwb" style={{ color }}>
				<FormatedCurrency
					value={currentRevenue}
					precision={0}
					convertFromInteger
					currencyCode={currency}
				/>
			</h2>
			<h3 className="fwn">
				{formatPercent(percent)} of{" "}
				<strong>
					<FormatedCurrency
						value={goalRevenue}
						precision={0}
						convertFromInteger
						currencyCode={currency}
					/>{" "}
					goal
				</strong>
			</h3>
			<div
				className={cx(
					styles.thermoContainer,
					styles["thermoContainer-" + location]
				)}
			>
				<span
					className={styles.thermoInidi}
					style={{ backgroundColor: color, width: percent * 100 + "%" }}
				/>
			</div>
		</Pane>
	)
}

export default ProgressBar
