import React, { useEffect } from "react"
import { loader } from "graphql.macro"
import { useLazyQuery } from "@apollo/react-hooks"
import { WbcxURL } from "@webconnex/rainbow/utils/url"
import useAuth from "../../hooks/use-auth/use-auth"
import { PageError } from "../../components/page-error/page-error"

const confirmSupporter = loader(
	"../../graphql/queries/confirm-supporter.graphql"
)

const Confirm: React.FC = () => {
	const errorMessage = (
		<PageError
			message={"It looks like your confirmation code isn't working."}
		/>
	)

	const { setSession } = useAuth("p2p_wbcx_session")
	const [confirmSupporterQuery, { loading, data, called }] = useLazyQuery(
		confirmSupporter,
		{
			fetchPolicy: "cache-and-network",
		}
	)
	const {
		query: { token },
	} = new WbcxURL(window.location.href, true)

	useEffect(() => {
		confirmSupporterQuery({
			variables: {
				token,
			},
		})
	}, [])

	if (!token) {
		return errorMessage
	}

	if (loading) {
		return null
	}

	if (!loading && !data?.response?.success && called) {
		return errorMessage
	}

	if (data?.response?.success && data?.response?.data) {
		setSession(data.response.data.token.token, data.response.data.token.ttl, {
			editURL: data.response.data.url,
			supporterId: data.response.data.supporterId,
			contactId: data.response.data.token.userId,
		})

		window.location.replace(data.response.data.url)
	}

	return null
}

export default Confirm
