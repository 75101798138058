// We should consider changing this to RegistrationData or
// OrderData when going to rainbow.
export type RegisterData = {
	hash: string
	data?: any
	errorMessage?: string
	statusEnd?: string
	meta?: any
	scaSecret?: string
}

export class RegisterError extends Error {
	private _data: RegisterData
	private _status: number

	get data() {
		return { ...this._data }
	}

	constructor(status: number, data: RegisterData) {
		super("error")
		this._status = status
		this._data = data
	}
}
