import { useQuery } from "@apollo/react-hooks"
import { loader } from "graphql.macro"
import { usePage } from "../../contexts"

const getVisitorsGQL = loader("../../graphql/queries/visitorMap.graphql")

function useVisitors() {
	const { result: pageResult, onSupporterPage } = usePage()

	if (!pageResult.loading) {
		const pageHash: string = onSupporterPage
			? pageResult?.data?.response?.supporter?.masterPage?.pageSnapshot
					?.formHash
			: pageResult?.data?.response?.publishedPage?.pageSnapshot?.formHash

		if (!pageHash) {
			return { result: { loading: false, data: {} } }
		}
		const variables: any = {
			pageHash,
		}
		if (onSupporterPage) {
			variables.supporterId = onSupporterPage
		}

		const result = useQuery(getVisitorsGQL, {
			variables,
		})

		return { result }
	}
	return { result: { loading: true, data: {} } }
}

export default useVisitors
