import React, { useMemo } from "react"
import { loader } from "graphql.macro"
import { useQuery } from "@apollo/react-hooks"
import Title from "@webconnex/rainbow/typography/title"
import Text from "@webconnex/rainbow/typography/text"
import List from "@webconnex/rainbow/components/list"
import Pane from "@webconnex/rainbow/layout/pane"
import Link from "@webconnex/rainbow/typography/link"
import { navigate } from "hookrouter"
import { parseP2PUrl } from "../../utils/url"
import useAuth from "../../hooks/use-auth/use-auth"
import { InvalidLink400 } from "../errors/400"

const pageListGQL = loader("../../graphql/queries/contact-supporters.graphql")

const PageList: React.FC = () => {
	const {
		loggedIn,
		session: { data: sessionData },
		updateSession,
		getSession,
	} = useAuth("p2p_wbcx_session")

	const { loading, data } = useQuery(pageListGQL, {
		variables: { limit: 250 },
		fetchPolicy: "cache-and-network",
	})

	if (!loggedIn || (!loading && !data.response.success)) {
		return <InvalidLink400 />
	}

	const goToSupporter = (path: string, supporterId: number) => {
		updateSession(undefined, undefined, { ...sessionData, supporterId })
		const {
			pathParams: { p2pSlug, domain: product, subdomain },
		} = parseP2PUrl("https://" + path)

		let queryParams = {}
		if (process.env.NODE_ENV === "development") {
			queryParams = {
				product,
				subdomain,
				form: p2pSlug,
			}
		}
		navigate(`/${p2pSlug}/fundraiser/${supporterId}`, false, queryParams)
	}

	const pages = useMemo(() => {
		if (data?.response?.success) {
			return data.response?.supporters?.records.map((supporter: any) => (
				<Link
					variant="primary"
					key={supporter.supporterId}
					onClick={() =>
						goToSupporter(supporter.masterPage.path, supporter.supporterId)
					}
				>
					{supporter.masterPage.pageSnapshot.name}
				</Link>
			))
		}
	}, [loading])

	const errorMessage = (
		<Pane className="mv-8 lg_w-50 mh-auto pa-4">
			<Title>My Fundraising Pages</Title>
			<Text>Choose which page you would like to edit.</Text>
			<List className="mt-2" spacing={2}>
				{pages}
			</List>
		</Pane>
	)
	return errorMessage
}

export default PageList
