import React from "react"
import { navigate } from "hookrouter"

export const toPage = (path: string) => {
	document.body.style.position = "initial"
	navigate(
		// The path to navigate to.
		path,
		// Preserves navigation history.
		false,
		// Query params.
		{},
		// Preserves old query params.
		false
	)
}
