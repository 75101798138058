import { RegistrationPrivate } from "../../types/registration"

export default ({
	$type: "form",
	$key: "$form",
	registrants: [
		{
			$fields: [
				{
					$type: "donationBox",
					$key: "donation_amount",
					$label: "Donation Amount",
					$repeater: [
						{
							$type: "donationFields",
							$key: "$donationFields",
							amount: {
								$type: "amountField",
								$label: "Amount",
								$value: "0",
							},
							fund: {
								$type: "fund",
								$label: "Fund",
								$value: "0",
							},
						},
					],
				},
				{
					$type: "textField",
					$key: "displayName",
					$label: "Name that payment will appear under",
					$value: "",
				},
				{
					$type: "textField",
					$key: "donationComment",
					$label: "Optional comment relating to payment",
					$value: "",
				},
				{
					$type: "textField",
					$key: "supporterName",
					$label: "Fundraiser Name",
					$value: "",
				},
				{
					$type: "checkbox",
					$key: "anonymousName",
					$label: "I want to donate anonymously",
					$value: false,
					$amount: "0",
					$discount: "0",
				},
			],
			$items: [
				{
					path: "registrants.donation_amount",
					amount: "",
					discount: "0",
					deposit: "0",
					adjustment: "0",
					deductible: "",
					category: "0",
					startsToday: true,
				},
			],
			$total: "",
		},
	],
	billing: {
		name: {
			first: "",
			last: "",
		},
		paymentGatewayId: 0,
		paymentMethod: "card",
		card: {
			cardNumber: "",
			expMonth: 0,
			expYear: 1900,
			cvvCode: "",
			saveCard: false,
			cardNumberEnc: "",
		},
		address: {
			street1: "",
			city: "",
			state: "",
			postalCode: "",
			country: "",
		},
		email: "",
		emailOptIn: false,
	},
} as any) as RegistrationPrivate

export const anonymousItem = {
	path: "registrants.anonymousName",
	amount: "0",
	discount: "0",
	deposit: "0",
	adjustment: "0",
	deductible: "0",
	quantity: 1,
	startsToday: true,
}
