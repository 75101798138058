import React, { useState, Fragment, useEffect } from "react"
import { loader } from "graphql.macro"
import { useMutation } from "@apollo/react-hooks"
import { navigate } from "hookrouter"
import {
	SuperNav,
	Masthead,
	Banner,
	Map,
	DonationForm,
	Button,
	EditPage,
	CustomHead,
} from "../../components"
import Page from "@webconnex/rainbow/layout/page"
import Title from "@webconnex/rainbow/typography/title"
import Text from "@webconnex/rainbow/typography/text"
import Link from "@webconnex/rainbow/typography/link"
import Pane from "@webconnex/rainbow/layout/pane"
import Modal from "@webconnex/rainbow/components/modal"
import Info from "./info/info"
import InfoIntro from "./info/info-intro"
import Aside from "../_shared/aside/aside"
import { StartDonation } from "../../types/donation"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"
import { parsePublishedPageResponse } from "../../utils"
import { toPage } from "../../utils/navigation"
import useAuth from "../../hooks/use-auth/use-auth"
import { usePage } from "../../contexts"
import { useVisitors } from "../../hooks"
import { getFullName } from "../../utils/supporters"

const editSupporter = loader("../../graphql/mutations/edit-supporter.graphql")

const Supporter = () => {
	const {
		loggedIn,
		session: { data: { supporterId } = { supporterId: "" } },
	} = useAuth("p2p_wbcx_session")
	const { result: pageResult, pageSlug, pathParams, refetch } = usePage()
	const {
		result: { loading: visitorsLoading, data: visitorData },
	} = useVisitors()
	const [editSupporterMutation] = useMutation(editSupporter)
	const [givingModal, setGivingModal] = useState<boolean>(false)
	const [starterAmount, setStarterAmount] = useState<string>("")
	const [editModal, setEditModal] = useState<boolean>(false)
	const [newPage, setNewPage] = useState<boolean>(true)
	const [shouldRefetch, setShouldRefetch] = useState<boolean>(false)

	const closeGiving = () => setGivingModal(false)
	const hideNewPage = () => setNewPage(false)
	const closeEdit = () => {
		setEditModal(false)
		setShouldRefetch(true)
		// TODO: the following line is necessary because the supporter edit click does
		// not trigger the closeModal function in modal.tsx. It should.
		document.body.style.position = "initial"
	}
	const startDonation: StartDonation = (_, __, amount) => {
		setStarterAmount(amount)
		setGivingModal(true)
	}
	useEffect(() => {
		if (refetch && shouldRefetch) {
			refetch()
			setShouldRefetch(false)
		}
	}, [shouldRefetch])

	const canEdit = loggedIn && supporterId === pathParams.supporterId

	if (pageResult.called && !pageResult?.data?.response?.success) {
		// If the supporter can't be found, return to the master page.
		toPage("/" + pageSlug)
	}

	if (!pageResult?.data?.response?.supporter?.masterPage?.pageSnapshot) {
		return null
	}

	let gatewayId = 0
	const gateways = pageResult?.data?.response?.supporter?.masterPage?.gateways

	gateways?.forEach((gateway: any) => {
		if (gateway.paymentMethodProvider.paymentMethod.identifier == "CC") {
			gatewayId = gateway.id
		}
	})

	const {
		name,
		currency,
		masthead,
		accentColor,
		fonts,
		formHash,
		p2pField,
		settings,
	} = parsePublishedPageResponse(
		pageResult?.data?.response?.supporter?.masterPage?.pageSnapshot
	)
	const {
		goalAmount,
		fundraisingDeadline,
		timeZone,
		introText,
		includeMasterPhotos,
		videoURL,
		confirmedAt,
		disabledAt,
		contact,
		customThankYou,
		profileImage,
		galleryImages,
		orderSum,
		orderMax,
	} = pageResult?.data?.response?.supporter || {}

	if (disabledAt !== null) {
		// this page is disabled
		navigate("/" + pageSlug, false, {}, false)
	}

	const mastheadComponent = <Masthead src={masthead.path} />

	const images = includeMasterPhotos
		? (galleryImages || []).concat(p2pField.galleryImages)
		: galleryImages

	return (
		<Fragment>
			<CustomHead
				fonts={fonts}
				settings={settings}
				title={contact.profile.firstName.value + " | " + name}
				formHash={formHash}
				supporterId={pathParams.supporterId}
				trackCheckpoints={true}
			/>
			<SuperNav
				canCreatePage={p2pField.allowSupporters}
				contactEmail={p2pField.contactEmail}
				pageSlug={pageSlug}
				handleEdit={canEdit ? setEditModal : undefined}
			/>
			<Masthead
				src={masthead.path}
				align={masthead.align}
				height={masthead.height}
			/>
			{newPage && !confirmedAt && loggedIn && (
				<Pane className="bg-gray-10 ph-4 pv-8 relative" size={2}>
					<Button
						emphasis="low"
						className="absolute"
						style={{ top: "10px", right: "10px" }}
						onClick={hideNewPage}
					>
						<Text style={{ fontSize: "3em" }} variant="light">
							&times;
						</Text>
					</Button>
					<Pane className="max-w-40 ml-auto mr-auto">
						<Title style={{ color: accentColor }}>
							Almost done! Confirm your email address.
						</Title>
						<Text variant="light" size={3}>
							Having trouble confirming your email?{" "}
							<Link
								size={3}
								href={`mailto:${p2pField.contactEmail}`}
								variant="primary"
							>
								contact your organization
							</Link>{" "}
							for approval.
						</Text>
						<Text className="mt-4">
							You can now accept donations, but your page won’t be featured
							until you confirm your email address.
						</Text>
					</Pane>
				</Pane>
			)}
			<Banner
				src={p2pField.bannerImg}
				title={name}
				color={accentColor}
				masterPage={false}
				allowSupporters={p2pField.allowSupporters}
			/>
			<Page className="pv-8 mb-4 lg_mt-6 xl_ph-8">
				<Pane lg="row" className="justify-between xl_ph-4">
					<span className="lg_dn">
						<InfoIntro
							pageSlug={pageSlug}
							intro={introText}
							pageName={name}
							profileImage={profileImage}
							firstName={contact.profile.firstName.value}
							lastName={contact.profile.lastName.value}
						/>
					</span>
					<Aside
						pageSlug={pageSlug}
						currentRevenue={orderSum?.amount || 0}
						goalRevenue={goalAmount.amount}
						currency={goalAmount.currency as SupportedCurrencyCodes}
						color={accentColor}
						deadline={fundraisingDeadline}
						timeZone={timeZone}
						biggestGift={0}
						title={name}
						startDonation={startDonation}
						supporterId={pathParams.supporterId}
						hideSelection={true}
					/>
					<Info
						supporterView={true}
						pageSlug={pageSlug}
						intro={introText}
						videoURL={videoURL}
						pageName={name}
						profileImage={profileImage}
						firstName={contact.profile.firstName.value}
						lastName={contact.profile.lastName.value}
						color={accentColor}
						currency={currency as SupportedCurrencyCodes}
						galleryImages={images}
						showDonors={p2pField.showHistory}
					/>
				</Pane>
			</Page>
			{p2pField.showMap &&
				!visitorsLoading &&
				visitorData?.response?.success && (
					<Map
						visits={visitorData?.response?.visitorData?.geoData}
						totalVisitors={visitorData?.response?.visitorData?.totalVisitors}
						countries={visitorData?.response?.visitorData?.totalCountries}
						color={accentColor}
					/>
				)}
			<Modal
				show={givingModal}
				closeCallBack={closeGiving}
				size="large"
				title="Give Now"
			>
				<Fragment>
					{mastheadComponent}
					<DonationForm
						gatewayId={gatewayId}
						supporterName={
							getFullName(pageResult?.data?.response?.supporter) || ""
						}
						supporterId={pathParams.supporterId}
						domain={pathParams.domain}
						masterPagePath={
							pageResult?.data?.response?.supporter?.masterPage?.path
						}
						formName={name}
						defaultAmount={starterAmount}
						color={accentColor}
						biggestGift={orderMax?.amount || 0}
						currentRevenue={orderSum?.amount || 0}
						goalRevenue={goalAmount.amount}
						coverFee={p2pField.coverFee}
						currency={goalAmount.currency as SupportedCurrencyCodes}
						forceAnonymous={p2pField.anonymizeDonors}
					/>
				</Fragment>
			</Modal>
			{canEdit && (
				<Modal
					show={editModal}
					closeCallBack={closeEdit}
					size="large"
					title="Edit Page"
				>
					<Fragment>
						{mastheadComponent}
						<EditPage
							supporterId={pathParams.supporterId!}
							firstName={contact.profile.firstName.value}
							lastName={contact.profile.lastName.value}
							profileImage={profileImage}
							galleryImages={galleryImages}
							goalAmount={goalAmount.amount}
							goalCurrency={goalAmount.currency}
							fundraisingDeadline={fundraisingDeadline}
							timeZone={timeZone}
							includeMasterPhotos={includeMasterPhotos}
							videoURL={videoURL}
							formName={name}
							editSupporterMutation={editSupporterMutation}
							color={accentColor}
							introText={introText}
							customThankYou={customThankYou}
							allowGoalChange={p2pField.allowGoalChange}
							allowDeadlineChange={p2pField.allowDeadlineChange}
							closeCallBack={closeEdit}
						/>
					</Fragment>
				</Modal>
			)}
		</Fragment>
	)
}

export default Supporter
