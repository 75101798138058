import React from "react"

import VideoPlayer from "../2r-video-player/video-player"

import Text from "@webconnex/rainbow/typography/text"
import Title from "@webconnex/rainbow/typography/title"

import { sanitizer } from "@webconnex/rainbow/utils/security"

interface Props {
	videoURL?: string
	headline: string
	description: string
}

const IntroMaster: React.FC<Props> = ({ videoURL, headline, description }) => {
	return (
		<div>
			{videoURL && <VideoPlayer url={videoURL} className="mb-7" />}
			<Title size={2} className="fwb">
				{headline}
			</Title>
			<Text
				dangerouslySetInnerHTML={{
					__html: sanitizer(description),
				}}
				as="div"
				className="mb-5 fr-view"
				style={{ lineHeight: "1.6em" }}
			/>
		</div>
	)
}
export default IntroMaster
