import React from "react"
import cx from "classnames"
import Page, { PageHeader } from "@webconnex/rainbow/layout/page"
import Pane from "@webconnex/rainbow/layout/pane"
import List from "@webconnex/rainbow/components/list"
import Icon from "@webconnex/rainbow/components/icon"
import Button from "../button/button"

import styles from "./banner.module.scss"

import { globalDefaultAccentColor } from "../../utils"

interface Props {
	src?: string
	title: string
	externalLink?: string
	externalLinkButtonText?: string
	color?: string
	regUrl?: string
	findAction?: any
	masterPage?: boolean
	allowSupporters?: boolean
}

const Banner: React.FC<Props> = ({
	src = "",
	color = globalDefaultAccentColor,
	title,
	externalLink = "",
	externalLinkButtonText = "",
	findAction,
	masterPage,
	allowSupporters,
}) => {
	return (
		<div
			className={styles.banner}
			style={{
				backgroundImage: `url('${src}')`,
			}}
		>
			<Page className="lg_pa-8">
				<Pane className={cx(styles.bannerContent, "xl_ph-6")}>
					<header
						className={cx(
							masterPage ? "mb-5" : "dn lg_db",
							"w-100 max-w-100 ma-0"
						)}
					>
						<h1>{title}</h1>
					</header>
					{masterPage && findAction && (
						<List
							className="items-center z-1 relative"
							childClassName="w-100 flex-grow-1 lg_flex-grow-0 lg_w-auto"
							md="row"
							spacing={3}
							as="nav"
						>
							{externalLink && externalLinkButtonText && (
								<Button
									className="white fwb w-100"
									style={{ background: color }}
									as="a"
									href={externalLink}
									target="_blank"
									size="default"
								>
									{externalLinkButtonText}
								</Button>
							)}
							{allowSupporters && (
								<Button
									className="bg-white fwb w-100"
									onClick={findAction}
									size="default"
								>
									<Icon iconName="zoom" placement="left" />
									Find a Fundraiser Page
								</Button>
							)}
						</List>
					)}
				</Pane>
			</Page>
			<div className={styles.gradient} />
		</div>
	)
}

export default Banner
