import React, { Fragment, useState, useMemo } from "react"
import Big from "big.js"
import { SupportedCurrencyCodes } from "@webconnex/rainbow/_shared/currency/currency-types"
import {
	Banner,
	DonationForm,
	Map,
	Masthead,
	SuperNav,
	SupporterSearch,
	CustomHead,
} from "../../components/"
import { StartDonation } from "../../types/donation"
import { parsePublishedPageResponse } from "../../utils"
import Aside from "../_shared/aside/aside"
import Info from "./info/info"
import { usePage } from "../../contexts/published-page"
import { useVisitors } from "../../hooks"
import Page from "@webconnex/rainbow/layout/page"
import Pane from "@webconnex/rainbow/layout/pane"
import Modal from "@webconnex/rainbow/components/modal"

const Master: React.FC = () => {
	const { pageSlug, result: pageResult, pathParams } = usePage()
	const {
		result: { loading: visitorsLoading, data: visitorData },
	} = useVisitors()

	const [givingModal, setGivingModal] = useState<boolean>(false)
	const [searchModal, setSearchModal] = useState<boolean>(false)
	const [selectedSupporter, setSelectedSupporter] = useState({
		name: "",
		id: 0,
	})
	const [starterAmount, setStarterAmount] = useState<string>("")

	const startDonation: StartDonation = (name, id, amount) => {
		setSelectedSupporter({ name, id })
		setStarterAmount(amount)
		setGivingModal(true)
	}

	if (!pageResult?.data?.response?.publishedPage?.pageSnapshot) {
		return null
	}

	let gatewayId = 0
	const gateways = pageResult?.data?.response?.publishedPage?.gateways

	gateways?.forEach((gateway: any) => {
		if (gateway.paymentMethodProvider.paymentMethod.identifier == "CC") {
			gatewayId = gateway.id
		}
	})

	const {
		name,
		currency,
		formHash,
		masthead,
		accentColor,
		fonts,
		p2pField,
		settings,
	} = parsePublishedPageResponse(
		pageResult?.data.response.publishedPage.pageSnapshot
	)

	const currentRevenue = useMemo(() => {
		const pageRevenue = Big(
			pageResult?.data?.response?.publishedPage?.orderSum?.amount || 0
		)
		const offlineRevenue = Big(p2pField.offlineContributions || 0)
		return parseInt(pageRevenue.plus(offlineRevenue).toString())
	}, [pageResult])

	const mastheadComponent = <Masthead src={masthead.path} />
	return (
		<Fragment>
			<CustomHead
				fonts={fonts}
				settings={settings}
				title={name}
				formHash={formHash}
				trackCheckpoints={true}
			/>
			<SuperNav
				canCreatePage={p2pField.allowSupporters}
				contactEmail={p2pField.contactEmail}
				pageSlug={pageSlug}
			/>
			<Masthead
				src={masthead.path}
				align={masthead.align}
				height={masthead.height}
			/>
			<Banner
				src={p2pField.bannerImg}
				title={name}
				color={accentColor}
				externalLink={p2pField.externalLink}
				externalLinkButtonText={p2pField.externalLinkButtonText}
				allowSupporters={p2pField.allowSupporters}
				findAction={() => setSearchModal(true)}
				masterPage
			/>
			<Page className="pb-8 mb-4 lg_mt-6 lg_pt-8 xl_ph-8">
				<Pane lg="row" className="justify-between xl_ph-4">
					<Aside
						currentRevenue={currentRevenue}
						goalRevenue={p2pField.goal}
						currency={currency as SupportedCurrencyCodes}
						color={accentColor}
						deadline={p2pField.deadline}
						timeZone={p2pField.timeZone}
						canCreatePage={p2pField.allowSupporters}
						biggestGift={
							pageResult?.data?.response?.publishedPage?.orderMax?.amount
						}
						generalFundId="general"
						title={name}
						startDonation={startDonation}
						pageSlug={pageSlug}
					/>
					<Info
						pageSlug={pageSlug}
						videoURL={p2pField.videoURL}
						headline={p2pField.introHeadline}
						description={p2pField.intro}
						color={accentColor}
						currency={currency as SupportedCurrencyCodes}
						images={p2pField.galleryImages}
						findAction={() => setSearchModal(true)}
						showSupporters={p2pField.allowSupporters && p2pField.showPages}
						showDonors={p2pField.showHistory}
					/>
				</Pane>
			</Page>

			{p2pField.showMap &&
				!visitorsLoading &&
				visitorData?.response?.success && (
					<Map
						visits={visitorData?.response?.visitorData?.geoData}
						totalVisitors={visitorData?.response?.visitorData?.totalVisitors}
						countries={visitorData?.response?.visitorData?.totalCountries}
						color={accentColor}
					/>
				)}
			<Modal
				size="large"
				title="Give Now"
				show={givingModal}
				closeCallBack={() => setGivingModal(false)}
			>
				<Fragment>
					{mastheadComponent}
					<DonationForm
						gatewayId={gatewayId}
						supporterName={selectedSupporter.name}
						supporterId={selectedSupporter.id}
						formName={name}
						defaultAmount={starterAmount}
						color={accentColor}
						biggestGift={
							pageResult?.data?.response?.publishedPage?.orderMax?.amount || 0
						}
						currentRevenue={currentRevenue}
						goalRevenue={p2pField.goal}
						coverFee={p2pField.coverFee}
						currency={currency as SupportedCurrencyCodes}
						forceAnonymous={p2pField.anonymizeDonors}
						domain={pathParams.domain}
						masterPagePath={pageResult?.data?.response?.publishedPage?.path}
					/>
				</Fragment>
			</Modal>
			<Modal
				size="large"
				title="Find a Fundraising Page"
				show={searchModal}
				closeCallBack={() => setSearchModal(false)}
			>
				{mastheadComponent}
				<SupporterSearch title={name} color={accentColor} />
			</Modal>
		</Fragment>
	)
}

export default Master
